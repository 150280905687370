import React from 'react';

export const SettignsMail = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.02148 3.41406H20.9785C22.0954 3.41406 23 4.32427 23 5.43555V17.5645C23 18.6823 22.0888 19.5859 20.9785 19.5859H2.02148C0.913935 19.5859 0 18.6862 0 17.5645V5.43555C0 4.32625 0.900997 3.41406 2.02148 3.41406ZM2.30458 4.76172C2.71759 5.17253 9.82518 12.2427 10.0706 12.4868C10.4524 12.8686 10.96 13.0789 11.5 13.0789C12.04 13.0789 12.5476 12.8686 12.9307 12.4855C13.0957 12.3214 20.1249 5.32926 20.6954 4.76172H2.30458ZM21.6523 17.2902V5.71069L15.8288 11.5036L21.6523 17.2902ZM20.6946 18.2383L14.8733 12.454L13.8823 13.4397C13.246 14.0761 12.3999 14.4265 11.5 14.4265C10.6001 14.4265 9.75402 14.0761 9.11892 13.441L8.12673 12.454L2.30544 18.2383H20.6946ZM1.34766 17.2902L7.17124 11.5036L1.34766 5.71069V17.2902Z"
        fill="#0C84FC"
      />
    </svg>
  );
};
