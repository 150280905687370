import { CircularProgress } from '@mui/material';
import React from 'react';

import { LoadingContainer, LoadingFullContainer } from './Loading.style';

export const Loading = () => (
  <LoadingContainer>
    <CircularProgress />
  </LoadingContainer>
);

export const LoadingFull = () => (
  <LoadingFullContainer>
    <CircularProgress />
  </LoadingFullContainer>
);
