import { Box, FormControlLabel, useTheme } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { CustomButton } from '../../common';
import { StyledAvatar } from '../../common/CustomAvatar';
import { CustomText } from '../../styles';

import {
  DateText,
  IOSSwitch,
  MessagesContainer,
  PersonInfoContainer,
  PersonInfoContent,
} from './InternalSupportTicket.style';

interface InternalSupportTicketMessagesProps {
  resolved: boolean;
  main: boolean;
  body: string;
  date: number;
  from: { photo: string; name: string; email: string };
  id: string;
  isFromExternalSupport: boolean;
  subject: string;
  exportExternal?: boolean;
}

export const InternalSupportTicketMessages: React.FC<InternalSupportTicketMessagesProps> = ({
  resolved,
  main,
  body,
  date,
  from,
  id,
  isFromExternalSupport,
  subject,
  exportExternal,
}) => {
  const theme = useTheme();
  return (
    <MessagesContainer bottom="ok">
      <PersonInfoContainer>
        <PersonInfoContent gap={19}>
          <StyledAvatar height={main ? 82 : 37} width={main ? 82 : 37} src={from.photo} />
          <Box>
            <CustomText fontSize={main ? 1.2 : 1} bold={700}>
              From: {from.name}
            </CustomText>
            <CustomText>{from.email}</CustomText>
          </Box>
        </PersonInfoContent>
        {main &&
          !exportExternal &&
          (!resolved ? (
            <CustomButton
              content="unresolved"
              bgcolor={theme.color.graphRed}
              textcolor={theme.palette.common.white}
            />
          ) : (
            <CustomButton
              content="resolved"
              bgcolor={theme.color.depositGreen}
              textcolor={theme.palette.common.white}
            />
          ))}
      </PersonInfoContainer>

      <PersonInfoContainer>
        <PersonInfoContent gap={9}>
          {main && (
            <>
              <CustomText bold={700}>Subject:</CustomText>
              <CustomText bold={700}>{subject}</CustomText>
            </>
          )}
        </PersonInfoContent>

        <DateText>{moment.unix(date).format('MM.DD.YYYY, HH:MM')}</DateText>
      </PersonInfoContainer>

      <CustomText bold={300}>{body}</CustomText>
    </MessagesContainer>
  );
};
