import { useTheme } from "@mui/material";
import React, { useState } from "react";

export const ItemTrash: React.FC<{ onClick?: () => void; }> = ({
  onClick,
}) => {
  const theme = useTheme();
  const [hovered, setHovered] = useState(false);

  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.94531 15.8125H10.8359C11.0586 15.8125 11.2812 15.627 11.2812 15.3672V7.35156C11.2812 7.12891 11.0586 6.90625 10.8359 6.90625H9.94531C9.68555 6.90625 9.5 7.12891 9.5 7.35156V15.3672C9.5 15.627 9.68555 15.8125 9.94531 15.8125ZM16.0312 3.34375H12.9512L11.6895 1.26562C11.3926 0.783203 10.7246 0.375 10.168 0.375H6.41992C5.86328 0.375 5.19531 0.783203 4.89844 1.26562L3.63672 3.34375H0.59375C0.259766 3.34375 0 3.64062 0 3.9375V4.53125C0 4.86523 0.259766 5.125 0.59375 5.125H1.1875V17.5938C1.1875 18.5957 1.9668 19.375 2.96875 19.375H13.6562C14.6211 19.375 15.4375 18.5957 15.4375 17.5938V5.125H16.0312C16.3281 5.125 16.625 4.86523 16.625 4.53125V3.9375C16.625 3.64062 16.3281 3.34375 16.0312 3.34375ZM6.3457 2.26758C6.38281 2.23047 6.49414 2.15625 6.53125 2.15625H6.56836H10.0566C10.0938 2.15625 10.2051 2.23047 10.2422 2.26758L10.873 3.34375H5.71484L6.3457 2.26758ZM13.6562 17.5938H2.96875V5.125H13.6562V17.5938ZM5.78906 15.8125H6.67969C6.90234 15.8125 7.125 15.627 7.125 15.3672V7.35156C7.125 7.12891 6.90234 6.90625 6.67969 6.90625H5.78906C5.5293 6.90625 5.34375 7.12891 5.34375 7.35156V15.3672C5.34375 15.627 5.5293 15.8125 5.78906 15.8125Z" fill={hovered ? theme.palette.error.light : "#979797"}/>
    </svg>
  );
}
