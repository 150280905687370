import { Divider, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { createTicket } from '../../api';
import { CustomButton, InputField } from '../../common';
import { CustomModal } from '../../components/CustomModal/CustomModal';
import { useForm } from '../../hooks/useForm';
import { useNotification } from '../../hooks/useNotification';
import { CustomText } from '../../styles';
import { FilterModal, ModalContent } from '../Transactions/FilterModal';

interface ExternalSupportSendProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: any;
}

export const InternalSupportSend: React.FC<ExternalSupportSendProps> = ({
  isOpen,
  onClose,
  refetch,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { notifyError } = useNotification();
  const { values, onChange } = useForm<{
    subject: string;
    message: string;
    exportExternal: true;
  }>({ subject: '', message: '', exportExternal: true });

  const handleSubmit = async () => {
    setLoading(true);
    const res = await createTicket(values);
    if (res.status === 200) {
      refetch();
      setLoading(false);
      onClose();
    } else {
      setLoading(false);
      notifyError(res.data.message);
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      banner="Create Ticket"
      onClose={() => {
        if (!loading) {
          onClose();
        }
      }}
      content={() => (
        <ModalContent component="form">
          <InputField
            id="subject"
            value={values.subject}
            name="subject"
            info="Subject *"
            placeholder="Enter here ..."
            onChange={onChange}
          />

          <InputField
            id="message"
            info="Message *"
            placeholder="Enter here ..."
            value={values.message}
            name="message"
            multiline
            onChange={onChange}
            rows={13}
          />

          <CustomButton
            content="SEND"
            width="100%"
            type="submit"
            onClick={handleSubmit}
            loading={loading}
          />
        </ModalContent>
      )}
    ></CustomModal>
  );
};
