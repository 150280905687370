import React from 'react';

import { ChartInfoContainer, DescContainer } from './ChartInfo.style';
import { CustomText } from '../../styles';
import { Flex, SmallCustomAvatar } from '../../common';

interface UserInfoPieChartProps {
  name: string | null;
  image: string | null;
}

export const UserInfoPieChart: React.FC<UserInfoPieChartProps> = ({ name, image }) => (
  <ChartInfoContainer padding="10px 20px">
    <DescContainer gap={5}>
      <SmallCustomAvatar image={image ? image : ''} />
      <Flex column gap="0px" noCenter>
        {name && <CustomText bold={700}>{name}</CustomText>}
        <CustomText>{name ? 'STUDENT' : 'All Students'}</CustomText>
      </Flex>
    </DescContainer>
  </ChartInfoContainer>
);
