import { capitalize } from '@mui/material';

export const decodeUrl = (lnk: string) => {
  const arr = lnk.split('/');
  return arr[arr.length - 1];
};

export const formatUrl = (lnk: string) =>
  lnk
    .split('/')
    .map((a: string) => capitalize(a))
    .join(' / ');
export const makeLink = (lnk: string) => lnk.split(' / ').map((item: string) => item.toLowerCase());

export const randomIntFromInterval = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const thousandToK = (number: number) => {
  if (number / 10000 > 1) {
    return number / 1000 + 'k';
  }
  return number;
};

export const removeEmpty = (obj: {}) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));

export const getBase64 = (file: any) => {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL;
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};
