import { Avatar, Box, styled, Tabs } from '@mui/material';

export const StyledAvatar = styled(Avatar)`
  height: 37px;
  width: 37px;
`;

export const StyledTabs = styled(Tabs)`
  height: 75px;
  width: calc(100% - 40px);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  margin: 0 auto;

  & .MuiTab-root {
    flex: 0 0 9%;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.common.black};
  }
`;

export const ListContainer = styled(Box)`
  width: 100%;
  padding: 0 20px 100px 20px;
  position: relative;
  gap: 12px;
  flex: 1;
  overflow: scroll;
  display: flex;
  flex-direction: column;
`;

export const TabsContainer = styled(Tabs)<{ width?: string }>`
  flex: 1;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[400]};

  button {
    font-weight: 800;
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  [aria-selected='true'] {
    background: -webkit-linear-gradient(50.23deg, #54b1e5 9.71%, #0c84fc 108.89%);
    -webkit-background-clip: text;
  }

  & .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.background.blue};
  }
`;

export const FiltersContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 4px;
  }
`;
