import { useTheme } from '@mui/material';
import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { Flex } from '../../../../common';
import { CustomText } from '../../../../styles';
import { Right } from '../Transactions/TransactionsAnalytics.style';

export const SmallPieChart: React.FC<{
  category: { id: string; name: string; hexColor: string };
  totalCount: number;
  totalSum: number;
}> = ({ category, totalCount, totalSum }) => {
  const theme = useTheme();
  const data = [
    { name: category.name, value: totalCount, color: `#${category.hexColor}` },
    { name: 'Rest', value: totalSum - totalCount, color: theme.color.borderGrey },
  ];
  return (
    <ResponsiveContainer height="100%" width="100%">
      <Flex fullHeight>
        <Flex fullHeight>
          <ResponsiveContainer width="40%" height="100%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius="100%"
                dataKey="value"
              >
                {data.map((item: any, index: number) => (
                  <Cell key={index} fill={item.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <Right>
            <Flex column contectCenter gap="0px">
              <Flex noFlex>
                <CustomText uppercase bold={700}>
                  {category.name}
                </CustomText>
              </Flex>
              <Flex noFlex gap="7px">
                <CustomText fontSize={1.7}>{totalCount} / </CustomText>
                <CustomText fontSize={1.7} bold={700}>
                  {Math.floor((totalCount / totalSum) * 100)}%
                </CustomText>
              </Flex>
            </Flex>
          </Right>
        </Flex>
      </Flex>
    </ResponsiveContainer>
  );
};
