import { Stack, useMediaQuery } from '@mui/material';
import React from 'react';

import { CustomButton, Flex } from '../../common';
import { useAuth } from '../../hooks/useAuth';
import { CustomText } from '../../styles';
import { LogoutContainer } from './Logout.style';

interface LogoutProps {
  onClose: () => void;
}

export const Logout: React.FC<LogoutProps> = ({ onClose }) => {
  const { logout } = useAuth();

  return (
    <LogoutContainer>
      <CustomText margin="0 0 9% 0">Are you sure you want to logout?</CustomText>
      <Flex>
        <CustomButton content="No" width="100%" bgcolor="#ECECEC" invert onClick={onClose} />
        <CustomButton content="Logout" width="100%" onClick={logout} />
      </Flex>
    </LogoutContainer>
  );
};
