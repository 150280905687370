import { Box, styled } from '@mui/material';

export const PageContainer = styled(Box)`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: ${({ theme }) => theme.color.lightGrey};
`;

export const FormContainer = styled(Box)`
  width: 40%;
  gap: 3rem;
  display: flex;
  align-items: space-evenly;
  justify-content: space-evenly;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  z-index: 11;
`;

export const TopImageContainer = styled(Box)<{ src: string }>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 40vh;
  background-image: url(${({ src }) => src});
  background-position: bottom;
  background-size: cover;
  display: flex;
  padding: 20px;
`;
export const BottomImageContainer = styled(Box)<{ src: string }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 75vh;
  background-image: url(${({ src }) => src});
  background-position: top;
  background-size: cover;
`;
