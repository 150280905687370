import React from 'react';

export const FullScreen = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_782_1496)">
      <path
        d="M4.75 17.8125V19H0.59375C0.265814 19 0 18.7342 0 18.4062V14.25H1.1875V16.9753L6.70344 11.4594L7.54063 12.2966L2.02469 17.8125H4.75Z"
        fill="white"
      />
      <path
        d="M18.4059 19H14.2496V17.8125H16.9749L11.459 12.2966L12.2962 11.4594L17.8121 16.9753V14.25H18.9996V18.4062C18.9996 18.7342 18.7338 19 18.4059 19Z"
        fill="white"
      />
      <path
        d="M17.8121 2.02468L12.2962 7.54062L11.459 6.70343L16.9749 1.18749H14.2496V-1.09673e-05H18.4059C18.7338 -1.09673e-05 18.9996 0.265803 18.9996 0.593739V4.74999H17.8121V2.02468Z"
        fill="white"
      />
      <path
        d="M6.70344 7.5466L1.1875 2.02473V4.75004H0V0.593788C0 0.265853 0.265814 3.8147e-05 0.59375 3.8147e-05H4.75V1.18754H2.02469L7.54063 6.70348L6.70344 7.5466Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_782_1496">
        <rect width="19" height="19" fill="white" transform="matrix(1 0 0 -1 0 19)" />
      </clipPath>
    </defs>
  </svg>
);
