import { Skeleton, useTheme } from '@mui/material';
import { format } from 'date-fns';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, SmallCustomAvatar, WhiteContainer } from '../../common';
import { ROUTES } from '../../constants';
import { CustomText } from '../../styles';

interface TransactionItemProps {
  name: string;
  type: string;
  date: number;
  price: number;
  imageUrl?: string;
  id: string;
}

export const TransactionsItem: React.FC<TransactionItemProps> = ({
  name,
  type,
  date,
  price,
  imageUrl,
  id,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <WhiteContainer noHeight>
      <Flex flex={15} gap={2} sx={{ cursor: 'pointer' }}>
        <SmallCustomAvatar image={imageUrl} />
        <CustomText fontSize={1.1} bold={700}>
          {name}
        </CustomText>
      </Flex>
      <Flex flex={15}>
        <CustomText>{type}</CustomText>
      </Flex>
      <Flex flex={10}>
        <CustomText>{moment.unix(date).format('MMMM, DD, YYYY')}</CustomText>
      </Flex>
      <Flex flex={10}>
        <CustomText>{moment.unix(date).format('HH:mm')}</CustomText>
      </Flex>
      <Flex flex={3} flexEnd>
        <CustomText color={theme.background.blue}>£{price / 100}</CustomText>
      </Flex>
    </WhiteContainer>
  );
};

export const TransactionsItemSkeleton: React.FC = () => (
  <WhiteContainer>
    <Flex flex={15} gap={2}>
      <Skeleton height={37} width={37} variant="circular" />
      <Skeleton width={200} />
    </Flex>
    <Flex flex={15}>
      <Skeleton width={100} />
    </Flex>
    <Flex flex={10}>
      <Skeleton width={130} />
    </Flex>
    <Flex flex={10}>
      <Skeleton width={50} />
    </Flex>
    <Flex flex={3} flexEnd>
      <Skeleton width={30} />
    </Flex>
  </WhiteContainer>
);
