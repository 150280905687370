import { Stack, useTheme } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { DATE_FULL_MONTH, DATE_TIME } from "../../constants";

import { CustomText, TableContent, TableItem } from "../../styles";
import { StyledAvatar } from "../Transactions/Transactions.style";

interface OrderItemProps {
  suplierName: string;
  orderPlaced: Date;
  price: number;
  onClick: () => void;
  image?: any;
}

export const OrderItem: React.FC<OrderItemProps> = ({
  suplierName,
  orderPlaced,
  price,
  onClick,
  image,
}) => {
  const theme = useTheme();

  return (
    <TableItem onClick={onClick}>
      <TableContent width={28}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <StyledAvatar src={image.imageUrl} />
          <CustomText bold={700}>{suplierName}</CustomText>
        </Stack>
      </TableContent>
      <TableContent width={18}>
        <CustomText>{format(new Date(orderPlaced), DATE_FULL_MONTH)}</CustomText>
      </TableContent>
      <TableContent width={24}>
        <CustomText>{format(new Date(orderPlaced), DATE_TIME)}</CustomText>
      </TableContent>
      <TableContent width={20}>
        <CustomText color={theme.palette.success.light}>{`£${price.toFixed(2)}`}</CustomText>
      </TableContent>
    </TableItem>
  )
}
