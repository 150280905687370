import { Divider, Stack } from '@mui/material';
import React from 'react';

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { CustomText } from '../../styles';
import { terms, TermType } from './Terms.constants';

export const Policy = () => (
  <>
    <RouteInfo />
    <Divider variant="middle" />
    <Stack width="98%" margin="0 auto">
      {terms.map((item: TermType, index: number) => (
        <Stack key={index} justifyContent="flex-end" margin="50px 0 0 0">
          <CustomText fontSize={1.2} bold={700}>
            {item.headline}
          </CustomText>
          <CustomText margin="16px 0 0 0" fontSize={0.9} bold={300}>
            {item.content}
          </CustomText>
        </Stack>
      ))}
    </Stack>
  </>
);
