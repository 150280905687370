import React from 'react';

export const Folder = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3125 3.375H9.5625L7.3125 1.125H1.6875C0.755859 1.125 0 1.88086 0 2.8125V5.625H18V5.0625C18 4.13086 17.2441 3.375 16.3125 3.375ZM0 15.1875C0 16.1191 0.755859 16.875 1.6875 16.875H16.3125C17.2441 16.875 18 16.1191 18 15.1875V6.75H0V15.1875Z"
        fill="white"
      />
    </svg>
  );
};
