import { useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getMails } from '../api';

export const useMails = (
  searchTerm?: null | string,
  filter?: { groupId: null | string; schoolYear: null | number },
) => {
  const [mails, setMails] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const tabs: string[] = ['sent', 'favorites'];

  const { data, error, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['mails', activeTab, searchTerm, filter],
      async ({ pageParam = 1 }) => {
        const res = await getMails({
          page: pageParam,
          type: tabs[activeTab],
          ...filter,
        });
        return res;
      },
      {
        getNextPageParam: (lastPage, pages) => pages.length + 1,
      },
    );

  useEffect(() => {
    if (data) {
      const arr: any = data?.pages.map((item: any) => ({ ...item?.data?.data?.mails }));
      setMails(Object.values(arr));
    }
  }, [data]);

  return {
    mails,
    isLoading,
    tabs,
    activeTab,
    setActiveTab,
    isFetchingNextPage,
    fetchNextPage,
  };
};
