import React from 'react';

export const Filter = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.6724 3.67184C18.675 3.64494 18.6875 3.62131 18.6875 3.59375V0.96875C18.6875 0.6065 18.3942 0.3125 18.0312 0.3125H0.96875C0.6065 0.3125 0.3125 0.6065 0.3125 0.96875V3.59375C0.3125 3.62131 0.324969 3.64494 0.32825 3.67184C0.3335 3.71647 0.340063 3.75912 0.355156 3.80309C0.37025 3.84706 0.39125 3.88447 0.414219 3.92319C0.427344 3.94616 0.431937 3.97241 0.449 3.99406L6.875 12.3481V18.0312C6.875 18.2734 7.00822 18.4959 7.2215 18.6101C7.31862 18.6619 7.42494 18.6875 7.53125 18.6875C7.65856 18.6875 7.78522 18.6501 7.89547 18.5773L11.833 15.9523C12.0154 15.8302 12.125 15.6254 12.125 15.4062V12.3488L18.5517 3.99406C18.5681 3.97175 18.5733 3.9455 18.5871 3.92253C18.6101 3.88381 18.6304 3.84641 18.6455 3.80309C18.6599 3.75912 18.6665 3.71647 18.6724 3.67184ZM10.8125 15.0552L8.1875 16.8054V12.125C8.1875 12.0974 8.17503 12.0738 8.17175 12.0469C8.1665 12.0023 8.15994 11.9596 8.14484 11.9157C8.12975 11.8717 8.10875 11.8343 8.08578 11.7956C8.072 11.7726 8.06741 11.7463 8.051 11.7247L2.30159 4.25H12.7812C13.1442 4.25 13.4375 3.956 13.4375 3.59375C13.4375 3.2315 13.1442 2.9375 12.7812 2.9375H1.625V1.625H17.375V2.9375H14.75C14.3871 2.9375 14.0938 3.2315 14.0938 3.59375C14.0938 3.956 14.3871 4.25 14.75 4.25H16.6984L10.9483 11.7247C10.9319 11.747 10.9267 11.7732 10.9129 11.7969C10.8899 11.8356 10.8696 11.873 10.8545 11.9163C10.8394 11.9596 10.8335 12.0023 10.8276 12.0476C10.825 12.0738 10.8125 12.0974 10.8125 12.125V15.0552Z"
      fill="white"
    />
  </svg>
);
