import { useState, useEffect } from 'react';
import { useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import axios from '../config/axiosConf';

import { addProduct, editProduct, deleteProduct, updateProductFavorites } from '../api';
import { useNotification } from './useNotification';

export const useProducts = () => {
  const [products, setProducts] = useState<any[]>([]);
  const { data, error, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['products'],
      async ({ pageParam = 1 }) => {
        const res = await axios.get(`/product/vendor?page=${pageParam}&pageCount=15`);
        return res.data;
      },
      {
        getNextPageParam: (lastPage, pages) => pages.length + 1,
      },
    );
  const queryClient = useQueryClient();
  const { notify, notifyError } = useNotification();

  const mutationAdd = useMutation(addProduct, {
    onSuccess: () => {
      notify({ message: 'Product added successfully' });
      queryClient.invalidateQueries(['products']);
    },
    onError: (e: any) => {
      notifyError(e.message);
    },
  });

  const mutationEdit = useMutation(editProduct, {
    onSuccess: () => {
      notify({ message: 'Product edited successfully' });
      queryClient.invalidateQueries(['products']);
    },
    onError: (e: any) => {
      notifyError(e.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['products']);
    },
  });

  const mutationDelete = useMutation(deleteProduct, {
    onSuccess: () => {
      notify({ message: 'Product deleted successfully' });
      queryClient.invalidateQueries(['products']);
    },
    onError: (e: any) => {
      notifyError(e.message);
    },
  });

  const mutationUpdateFavoritesProduct = useMutation(updateProductFavorites, {
    onSuccess: () => {
      queryClient.invalidateQueries(['products']);
    },
    onError: (e: any) => {
      notifyError(e.message);
    },
  });

  useEffect(() => {
    if (data) {
      const arr = data.pages.map((item: any) => ({ ...item.data.products }));
      setProducts(arr);
    }
  }, [data]);

  return {
    products,
    error,
    isLoading,
    mutationAdd,
    mutationDelete,
    mutationEdit,
    mutationUpdateFavoritesProduct,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};
