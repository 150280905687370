import { Stack, useTheme } from "@mui/material";
import React from "react";
import { CustomText, TableContent, TableItem } from "../../styles";

interface OrderDetailItem {
  name: string;
  categoryName: string;
  quantity: number;
  price: number;
}

export const OrderDetailItem: React.FC<OrderDetailItem> = ({
  name,
  categoryName,
  quantity,
  price,
}) => {
  const theme = useTheme();
  const totalPrice = price * quantity;

  return (
    <TableItem>
      <TableContent width={28}>
        <Stack direction="row">
          <CustomText bold={700}>{name}</CustomText>
        </Stack>
      </TableContent>
      <TableContent width={18}>
        {categoryName}
      </TableContent>
      <TableContent width={24}>
        {quantity}
      </TableContent>
      <TableContent width={27}>
        {`£ ${price}`}
      </TableContent>
      <TableContent width={18}>
        <CustomText color={theme.palette.success.light}>{`£ ${totalPrice}`}</CustomText>
      </TableContent>
    </TableItem>
  )
}
