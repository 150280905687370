import React from 'react';

export const SettingsIcon: React.FC<{ color?: string }> = ({ color }) => (
  <svg width="62" height="64" viewBox="0 0 62 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M60.9876 20.825C61.4001 21.9 61.0501 23.1125 60.2001 23.9L54.7876 28.825C54.9251 29.8625 55.0001 30.925 55.0001 32C55.0001 33.075 54.9251 34.1375 54.7876 35.175L60.2001 40.1C61.0501 40.8875 61.4001 42.1 60.9876 43.175C60.4376 44.6625 59.7752 46.1 59.0252 47.4625L58.4376 48.475C57.6126 49.85 56.6876 51.15 55.6751 52.3875C54.9251 53.275 53.7126 53.5875 52.6126 53.2375L45.6502 51.0125C43.9752 52.3 42.0126 53.375 40.1502 54.2L38.5877 61.3375C38.3377 62.4625 37.4627 63.2625 36.3126 63.5625C34.5877 63.85 32.8127 64 30.8877 64C29.1877 64 27.4127 63.85 25.6877 63.5625C24.5377 63.2625 23.6627 62.4625 23.4127 61.3375L21.8502 54.2C19.8752 53.375 18.0252 52.3 16.3502 51.0125L9.39265 53.2375C8.28765 53.5875 7.0689 53.275 6.3289 52.3875C5.31515 51.15 4.39015 49.85 3.56515 48.475L2.98015 47.4625C2.2214 46.1 1.5614 44.6625 1.00765 43.175C0.602651 42.1 0.943901 40.8875 1.8014 40.1L7.2089 35.175C7.0714 34.1375 7.00015 33.075 7.00015 32C7.00015 30.925 7.0714 29.8625 7.2089 28.825L1.8014 23.9C0.943901 23.1125 0.602651 21.9125 1.00765 20.825C1.5614 19.3375 2.22265 17.9 2.98015 16.5375L3.5639 15.525C4.39015 14.15 5.31515 12.85 6.3289 11.6187C7.0689 10.725 8.28765 10.415 9.39265 10.7675L16.3502 12.9875C18.0252 11.695 19.8752 10.62 21.8502 9.80375L23.4127 2.66625C23.6627 1.53125 24.5377 0.63 25.6877 0.43875C27.4127 0.150125 29.1877 0 31.0002 0C32.8127 0 34.5877 0.150125 36.3126 0.43875C37.4627 0.63 38.3377 1.53125 38.5877 2.66625L40.1502 9.80375C42.0126 10.62 43.9752 11.695 45.6502 12.9875L52.6126 10.7675C53.7126 10.415 54.9251 10.725 55.6751 11.6187C56.6876 12.85 57.6126 14.15 58.4376 15.525L59.0252 16.5375C59.7752 17.9 60.4376 19.3375 60.9876 20.825ZM31.0002 42C36.5252 42 41.0001 37.525 41.0001 31.8875C41.0001 26.475 36.5252 21.8875 31.0002 21.8875C25.4752 21.8875 21.0002 26.475 21.0002 31.8875C21.0002 37.525 25.4752 42 31.0002 42Z"
      fill={color ? color : '#0C84FC'}
    />
  </svg>
);
