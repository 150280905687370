import { useTheme, Skeleton, CircularProgress } from '@mui/material';
import moment from 'moment';
import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  Tooltip,
  YAxis,
  XAxis,
  Line,
} from 'recharts';
import { ChartTitle } from './ChartTitle';
import { ChartContainer, ChartContainerContent } from './Chart.style';
import { thousandToK } from '../../helpers';
import { Flex } from '../../common';
import { CustomText } from '../../styles';

interface LineChartPropTypes {
  data: any[];
  color?: string;
  title: string;
  openFilter?: () => void;
  filter?: any;
  loading: boolean;
  XLineName?: string;
  YLineName?: string;
  noFormat?: boolean;
  initialFilter?: any;
  realFilter?: any;
  clearFilter?: () => void;
  noPrefix?: boolean;
  secondLine?: {
    name: string;
    color: string;
  };
}

export const LineCharts: React.FC<LineChartPropTypes> = ({
  data,
  color,
  title,
  openFilter,
  filter,
  loading,
  XLineName,
  YLineName,
  secondLine,
  noFormat,
  noPrefix,
  initialFilter,
  realFilter,
  clearFilter,
}) => {
  const theme = useTheme();
  return (
    <ChartContainer>
      <ChartTitle
        title={title}
        onClick={openFilter}
        filter={filter}
        initialFilter={initialFilter}
        realFilter={realFilter}
        clearFilter={clearFilter}
      />
      <ChartContainerContent>
        {loading ? (
          <Flex fullHeight contectCenter>
            <CircularProgress size="4rem" />
            <CustomText fontSize={1.4} color={theme.background.blue}>
              Loading...
            </CustomText>
          </Flex>
        ) : (
          <ResponsiveContainer height="100%" width="100%">
            <LineChart data={data}>
              <CartesianGrid vertical={false} />
              <Tooltip />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickMargin={5}
                dataKey={YLineName ? YLineName : 'total'}
                padding={{ bottom: 20 }}
                tickFormatter={(value) => `${!noPrefix ? '€' : ''}${thousandToK(value / 100)}`}
              />
              <XAxis
                dataKey={XLineName ? XLineName : 'monthStartDate'}
                tickLine={false}
                tickMargin={10}
                padding={{ left: 20 }}
                tickFormatter={(value) => `${noFormat ? value : moment.unix(value).format('MMM')}`}
              />
              <Line
                type="linear"
                dataKey={YLineName ? YLineName : 'total'}
                stroke={color ? color : theme.background.blue}
                dot={{ r: 6, fill: color ? color : theme.background.blue }}
                activeDot={false}
                stroke-width={2}
              />
              {secondLine && (
                <Line
                  type="linear"
                  dataKey={secondLine.name ? secondLine.name : 'total'}
                  stroke={secondLine.color ? secondLine.color : theme.background.blue}
                  dot={{ r: 6, fill: secondLine.color ? secondLine.color : theme.background.blue }}
                  activeDot={false}
                  stroke-width={2}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        )}
      </ChartContainerContent>
    </ChartContainer>
  );
};
