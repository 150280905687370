import React, { useEffect } from 'react';
import { useForm } from '../../hooks/useForm';

import { YearInput, MonthInput } from '../../common/Input/DateInput';
import { CustomButton, Flex, SelectField } from '../../common';
import moment from 'moment';
import { FilterModal, FilterPropTypes } from '../../pages/Transactions/FilterModal';
import { useCategories } from '../../hooks/useCategories';
import { MenuItem } from '@mui/material';

interface BigFilterPropTypes extends FilterPropTypes {
  onSave: (value: any) => void;
}

export const FilterYearMonthCategory: React.FC<BigFilterPropTypes> = ({
  open,
  handleClose,
  onSave,
}) => {
  const { values, forceChange, onChange } = useForm<{
    year: string;
    month: number;
    category: {
      name: string;
      id: string;
    };
  }>({
    year: null,
    month: null,
    category: {
      name: null,
      id: null,
    },
  });
  const { categories, isLoading: categoriesIsloading } = useCategories();
  return (
    <FilterModal open={open} handleClose={handleClose}>
      <Flex column>
        <Flex>
          <YearInput
            value={values.year}
            onChange={(e: any) => {
              forceChange(moment(e).format('YYYY'), 'year');
            }}
            name="year"
            id="year"
            info="Year"
          />
          <MonthInput
            value={values.month}
            onChange={(e: any) => {
              forceChange(moment(e).format('M'), 'month');
            }}
            name="month"
            id="month"
            info="Month"
          />
        </Flex>
        <SelectField
          id="categoryName"
          info="Category Name"
          name="category"
          value={values.category}
          onChange={onChange}
        >
          {categories.map((category: any) => (
            <MenuItem key={category.id} value={category}>
              {category.name}
            </MenuItem>
          ))}
        </SelectField>
        <Flex>
          <CustomButton
            content="confirm"
            width="100%"
            height="56px"
            onClick={() => onSave(values)}
          />
        </Flex>
      </Flex>
    </FilterModal>
  );
};
