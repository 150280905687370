import { useTheme } from '@mui/material';
import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { CustomButton, Flex } from '../../../common';
import { RouteInfo } from '../../../components/RouteInfo/RouteInfo';
import { ROUTES } from '../../../constants';
import { useAppSelector } from '../../../store';
import { CustomText } from '../../../styles';
import { MessagesAndFormContainer } from '../../InternalSupportTicket/InternalSupportTicket.style';

import { MailMessage } from './MailMessage';
import { MailPageDetail } from './MailPageDetail';

export const MailPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { mailItem } = useAppSelector((state) => state.store);

  if (!mailItem) {
    return <Navigate to={ROUTES.MAIL} />;
  } else {
    return (
      <>
        <RouteInfo
          banner="Mail"
          noButton
          description="Connect instantly with your organisation"
          noLastRoute
        />
        <Flex column>
          <MailPageDetail onClick={() => navigate(ROUTES.MAIL)} />
          <MessagesAndFormContainer>
            <MailMessage {...mailItem} />
          </MessagesAndFormContainer>
        </Flex>
      </>
    );
  }
};
