import axios from '../config/axiosConf';

export const login = async (data: { email: string; password: string }) => {
  const res = await axios({
    method: 'post',
    url: '/authorization/vendor/signin',
    data,
  });

  return res;
};

export const logout = async () => {
  const res = await axios({
    method: 'post',
    url: '/authorization/signout',
  });

  return res;
};

// Orders

export const getOrders = async (props: any) => {
  const { date } = props.queryKey[1];
  if (!props.pageParam) {
    props.pageParam = 1;
  }
  const { pageParam } = props;

  if (date) {
    const res = await axios({
      method: 'get',
      url: `/order?page=${pageParam}&pageCount=10&date=${date}`,
    });

    return res;
  }

  const res = await axios({
    method: 'get',
    url: `/order?page=${pageParam}&pageCount=10`,
  });

  return res;
};

export const addOrder = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: '/order',
    data,
  });

  return res;
};

export const getOrdersOnHold = async ({ pageParam = 1 }) => {
  const res = await axios({
    method: 'get',
    url: `/order?page=${pageParam}&pageCount=10&onHold=true`,
  });

  return res;
};

export const createOrderOnHold = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: '/order/hold',
    data,
  });

  return res;
};

// Products

export const getProducts = async ({ pageParam = 1 }) => {
  const res = await axios({
    method: 'get',
    url: `/product/vendor?page=${pageParam}&pageCount=10`,
  });
  return res;
};

export const addProduct = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: '/product',
    data,
  });

  return res.data.data.data;
};

export const editProduct = async (data: any) => {
  const res = await axios({
    method: 'put',
    url: '/product',
    data,
  });

  return res;
};

export const getCategories = async () => {
  const res = await axios({
    method: 'get',
    url: '/product/categories',
  });

  return res;
};

export const deleteProduct = async (productId: string | null) => {
  const res = await axios({
    method: 'delete',
    url: `/product/${productId}`,
  });

  return res;
};

export const updateProductFavorites = async (productId: string[]) => {
  const res = await axios({
    method: 'post',
    url: '/product/favorites',
    data: {
      productsIds: productId,
    },
  });

  return res;
};

// Students

export const getStudents = async () => {
  const res = await axios({
    method: 'get',
    url: `/user/search?role=student&page=1&pageCount=10`,
  });

  return res;
};

// Transactions
export const getTransactionsTotal = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: 'transaction/vendor/total-amount',
    params,
  });

  return res;
};

export const getTransactions = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: `/transaction/vendor`,
    params,
  });

  return res;
};

// analytics

export const getOverview = async () => {
  const res = await axios({
    method: 'get',
    url: `analytics/vendor/overview`,
  });

  return res;
};

export const getAnalytics = async (analytic: string, filter?: any) => {
  let params: any;
  if (filter) {
    params = { ...filter };
    Object.keys(params).forEach((key: string) => {
      if (params[key] === '') {
        delete params[key];
      }
    });
  }
  const res = await axios({
    method: 'get',
    url: `/analytics/vendor/${analytic}`,
    params,
  });

  return res;
};

// MAIL

export const getMails = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: '/mail/all-vendor',
    params,
  });

  return res;
};

export const favoriteMail = async (mailId: string, favorite: boolean) => {
  const res = await axios({
    method: 'put',
    url: '/mail/favorite',
    data: { mailId, favorite },
  });

  return res;
};

export const composeMail = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: '/mail/compose-vendor',
    data,
  });
  return res;
};

// Users

export const getUsers = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: '/user/search',
    params,
  });

  return res;
};

// internal support

export const createTicket = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: `support/ticket`,
    data,
  });

  return res;
};

export const getInternalSupport = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: 'support/ticket/all',
    params,
  });

  return res;
};

export const getTicket = async (ticketId: string) => {
  const res = await axios({
    method: 'get',
    url: `support/ticket`,
    params: {
      ticketId,
    },
  });

  return res;
};

export const sendReply = async (ticketId: string, message: string) => {
  const res = await axios({
    method: 'post',
    url: `support/ticket/reply`,
    data: {
      ticketId,
      message,
    },
  });

  return res;
};
