import { createTheme, ThemeOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Theme {
    background: {
      blue: string;
    };
    color: {
      borderGrey: string;
      divider: string;
      graphYellow: string;
      graphRed: string;
      lightGrey: string;
      lightBlue: string;
      depositGreen: string;
    };
  }

  interface ThemeOptions {
    background: {
      blue: string;
    };
    color: {
      borderGrey: string;
      divider: string;
      graphYellow: string;
      graphRed: string;
      lightGrey: string;
      lightBlue: string;
      depositGreen: string;
    };
  }
}

export const theme: ThemeOptions = createTheme({
  background: {
    blue: '#0C84FC',
  },
  color: {
    lightGrey: '#ECECEC',
    borderGrey: '#979797',
    graphYellow: '#EDBB3C',
    graphRed: '#ED3C3C',
    divider: '#B3B3B3',
    lightBlue: '#54B1E5',
    depositGreen: '#05B60C',
  },
});
