import { CircularProgress, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
import { format } from "date-fns";

import { DATE_FULL_MONTH_ORDER, ROUTES } from "../../constants";
import { Loading, SectionHeaderInfo } from "../../common";
import { RouteInfo } from "../../components/RouteInfo/RouteInfo";
import { OrderItem } from "./OrderItem";
import { OrdersHeader } from "./OrdersHeader";
import { useOrders } from "../../hooks/useOrders";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { AddOrder } from "./AddOrder/AddOrder";
import { CustomButton as CustomInventoryButton } from "../Inventory/Inventory.style";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { CalendarButton } from "./Orders.style";
import { setOrderDetail } from "../../store/slice";
import { useNavigate } from "react-router-dom";

export const Orders = () => {
  const [showAddOrder, setShowAddOrder] = useState(false);
  const { ref, inView } = useInView();
  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    mutationOnHold,
    mutation,
    orders,
    date,
    setDate,
  } = useOrders();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  if(isLoading) return <Loading />;

  return (
    <>
      <RouteInfo margin="1% 0" onClick={() => setShowAddOrder(true)} />
      <SectionHeaderInfo
        banner={format(new Date(), DATE_FULL_MONTH_ORDER)}
        content={
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={new Date(date ?? Date.now())}
              onChange={(e) => setDate(e?.getTime())}
              disableFuture
              renderInput={(props) => <div style={{ position: 'relative' }}><CalendarButton {...props} /></div>}
            />
          </LocalizationProvider>
        }
      />

      {showAddOrder && (
        <CustomModal
          isOpen={showAddOrder}
          onClose={() => setShowAddOrder(false)}
          width="50%"
          content={() => <AddOrder mutation={mutation} mutationOnHold={mutationOnHold} />}
          banner="Create Order"
        />
      )}

      <Stack height="67vh" width="100%" padding="20px" spacing={2} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <OrdersHeader />

        {orders.map((items: any, index: number) => (
          <React.Fragment key={index}>
            {items.map((item: any, index: number) => (
              <OrderItem
                key={index}
                suplierName={item.student.name}
                orderPlaced={item.date}
                price={item.totalPrice}
                image={item.student}
                onClick={() => {
                  dispatch(setOrderDetail(item));
                  navigate(ROUTES.ORDER_DETAIL);
                }}
              />
            ))}
          </React.Fragment>
        ))}

        <CustomInventoryButton
            onClick={() => fetchNextPage()}
            disabled
            ref={ref}
          >
          {isFetchingNextPage ? (
            <Stack alignItems="center" direction="row">Loading more... <CircularProgress size={16} /></Stack>
          ) : 'No more items'}
        </CustomInventoryButton>
      </Stack>
    </>
  )
}
