import { styled, Box } from '@mui/material';

export const LayoutContainer = styled(Box)<{
  bgcolor?: string;
  padding?: string;
  noScroll?: string;
}>`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding};
  background-color: ${({ bgcolor }) => bgcolor || 'initial'};
  overflow: scroll;
  position: relative;

  @media (max-width: 768px) {
    overflow-y: ${({ noScroll }) => noScroll || 'auto'};
  }

  a {
    text-decoration: none;
  }
`;

export const AnalyticContainer = styled(Box)`
  max-height: 100%;
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
`;
