import { styled, Box } from '@mui/material';

export const AnalitycsProductsContainer = styled(Box)`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  max-height: 100%;
`;

export const AnalitycsProductsLeftContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const AnalitycsProductsRightContainer = styled(Box)`
  height: 100%;
  flex: 1;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FourPieChartCardContent = styled(Box)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
`;

export const PopularProductItemContainer = styled(Box)<{ last: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme, last }) => (!last ? theme.color.borderGrey : 'none')};
`;

export const PopularProductItemInfo = styled(Box)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 5px;
`;

export const PopularProductItemListContainer = styled(Box)<{ height: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${({ height }) => height}px;
  overflow-y: scroll;
`;
