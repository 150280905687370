import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderDetail } from '../types/OrderType';
import { MailMessageProps } from '../pages/Mail/MailPage/MailMessage';

const orderDetail = {
  date: 0,
  id: '',
  onHold: true,
  type: '',
  student: { name: 'Name' },
  totalPrice: 0,
  products: [],
};

export interface StoreType {
  notification: any;
  loggedIn: boolean;
  orderDetail: OrderDetail;
  mailItem: MailMessageProps | null;
}

const initialState: StoreType = {
  notification: null,
  loggedIn: false,
  orderDetail: orderDetail,
  mailItem: null,
};

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setNotification: (state: StoreType, action: PayloadAction<any>) => {
      state.notification = action.payload;
    },
    setLoggedIn: (state: StoreType, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setOrderDetail: (state: StoreType, action: PayloadAction<OrderDetail>) => {
      state.orderDetail = action.payload;
    },
    resetOrderDetail: (state: StoreType, action?: PayloadAction<any>) => {
      state.orderDetail = orderDetail;
    },
    setMailItem: (state, action: PayloadAction<any>) => {
      state.mailItem = action.payload;
    },
  },
});

export const { setNotification, setLoggedIn, setOrderDetail, resetOrderDetail, setMailItem } =
  storeSlice.actions;

export default storeSlice.reducer;
