import { MenuItem, Stack } from '@mui/material';
import React from 'react';

import { CustomButton, InputField, SelectField } from '../../common';
import { useForm } from '../../hooks/useForm';
import { useNotification } from '../../hooks/useNotification';
import { AddProductType } from '../../types';

interface AddProductFormProps {
  onSubmit: (event: any, values: any) => void;
  categories: any;
  loading: boolean;
}

export const AddProductForm: React.FC<AddProductFormProps> = ({
  onSubmit,
  categories,
  loading,
}) => {
  const { notifyError } = useNotification();
  const { values, onChange } = useForm<AddProductType>({
    name: '',
    categoryId: '',
    sku: '',
    stock: '',
    price: '',
  });

  const submitForm = (event: any) => {
    event.preventDefault();
    if (Object.values(values).some((value: any) => !value)) {
      notifyError('Please fill in all fields!');
      return;
    }
    onSubmit(event, {
      ...values,
      stock: parseInt(values.stock),
      price: values.price * 100,
    });
  };

  return (
    <Stack
      component="form"
      onSubmit={submitForm}
      height="100%"
      width="100%"
      padding="20px"
      direction="column"
      alignItems="center"
      justifyContent="space-evenly"
    >
      <InputField
        id="name"
        info="Product Name"
        name="name"
        placeholder="Enter here..."
        value={values.name}
        onChange={onChange}
      />
      <SelectField
        id="categoryName"
        info="Category Name"
        name="categoryId"
        value={values.categoryId}
        onChange={onChange}
      >
        {categories.map((category: any) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </SelectField>

      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={4}
      >
        <InputField
          id="sku"
          info="SKU"
          name="sku"
          placeholder="Enter here..."
          value={values.sku}
          onChange={onChange}
        />
        <InputField
          id="stock"
          info="Stock"
          type="number"
          name="stock"
          value={values.stock}
          onChange={onChange}
        />
        <InputField
          id="price"
          type="number"
          info="Price"
          name="price"
          value={values.price}
          onChange={onChange}
        />
      </Stack>
      <CustomButton loading={loading} content="Submit" width="100%" type="submit" margin="4% 0" />
    </Stack>
  );
};
