import React, { useEffect } from 'react';

import { ROUTES } from './constants';
import { Navigate, RouteObject, useNavigate, useRoutes } from 'react-router-dom';
import { Login } from './pages/Login/Login';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { VendorConsole } from './pages/VendorConsole/VendorConsole';
import { Overview } from './pages/Overview/Overview';
import { Inventory } from './pages/Inventory/Inventory';
import { Orders } from './pages/Orders/Orders';
import { Analytics } from './pages/Analytics/Analytics';
import { Earnings } from './pages/Analytics/pages/Earnings/Earnings';
import { AnalyticOrders } from './pages/Analytics/pages/AnalyticOrders/AnalyticOrders';
import { Transactions } from './pages/Transactions/Transactions';
import { NotFound } from './pages/NotFound/NotFound';
import { USER } from './config/axiosConf';
import { OrderDetail } from './pages/OrderDetail/OrderDetail';
import { Terms } from './pages/TermsAndPolicy/Terms';
import { TransactionsAnalytics } from './pages/Analytics/pages/Transactions/TransactionsAnalytics';
import { AnalitycsProducts } from './pages/Analytics/pages/Products/AnalitycsProducts';
import { Settings } from './pages/Settings/Settings';
import { Mail } from './pages/Mail/Mail';
import { MailPage } from './pages/Mail/MailPage/MailPage';
import { InternalSupport } from './pages/InternalSupport/InternalSupport';
import { InternalSupportTicket } from './pages/InternalSupportTicket/InternalSupportTicket';
import { Policy } from './pages/TermsAndPolicy/Policy';

const getRoutes = (isLogged: boolean): RouteObject[] => [
  {
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTES.DASHBOARD,
    element: isLogged ? <Dashboard /> : <Navigate to={ROUTES.LOGIN} />,
    children: [
      {
        path: '',
        element: <VendorConsole />,
      },
      {
        path: ROUTES.OVERVIEW,
        element: <Overview />,
      },
      {
        path: ROUTES.INVENTORY,
        element: <Inventory />,
      },
      {
        path: ROUTES.ORDERS,
        element: <Orders />,
      },
      {
        path: ROUTES.ORDER_DETAIL,
        element: <OrderDetail />,
      },
      {
        path: ROUTES.TRANSACTIONS,
        element: <Transactions />,
      },
      {
        path: ROUTES.MAIL,
        element: <Mail />,
      },
      {
        path: ROUTES.TERMS,
        element: <Terms />,
      },
      {
        path: ROUTES.POLICY,
        element: <Policy />,
      },
      {
        path: ROUTES.SETTIGNS,
        element: <Settings />,
      },
      {
        path: ROUTES.INTERNAL_SUPPORT,
        element: <InternalSupport />,
      },
      {
        path: `${ROUTES.INTERNAL_SUPPORT_TICKET}/:id`,
        element: <InternalSupportTicket />,
      },
      {
        path: `${ROUTES.MAIL_PAGE}/:id`,
        element: <MailPage />,
      },
      {
        path: ROUTES.ANALYTICS,
        element: <Analytics />,
        children: [
          {
            index: true,
            element: <Earnings />,
          },
          {
            path: ROUTES.ANALYTICS_ORDERS,
            element: <AnalyticOrders />,
          },
          {
            path: ROUTES.ANALYTICS_TRANSACTIONS,
            element: <TransactionsAnalytics />,
          },
          {
            path: ROUTES.ANALYTICS_PRODUCTS,
            element: <AnalitycsProducts />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTES.NOT_FOUND,
    element: <NotFound />,
  },
];

export const AppRoutes = () => {
  const routes = useRoutes(getRoutes(!!localStorage.getItem(USER)));

  return <>{routes}</>;
};
