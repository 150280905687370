import React from 'react';
import { Flex } from '../../../../common';
import { Earnings } from '../Earnings/Earnings';
import { CreditTransactionsChart } from './CreditTransactionsChart';
import { SchoolBillTransactionsChart } from './SchoolBillTransactionsChart';
import { TotalTransactionChart } from './TotalTransactionChart';
import { TotalTransactionsPieChart } from './TotalTransactionsPieChart';
import { TransactionsAnalyticsContainer } from './TransactionsAnalytics.style';

export const TransactionsAnalytics = () => {
  return (
    <TransactionsAnalyticsContainer>
      <TotalTransactionChart />
      <TotalTransactionsPieChart />
      <SchoolBillTransactionsChart />
      <CreditTransactionsChart />
    </TransactionsAnalyticsContainer>
  );
};
