import React from 'react';
import { Modal, styled, Box, useTheme, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CustomText } from '../../styles';
import { WhiteContainer } from '../../common';

export interface FilterPropTypes {
  children?: any;
  open: boolean;
  handleClose: () => void;
}

export const ModalContainer = styled(WhiteContainer)`
  position: absolute;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
`;
export const ModalTitle = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color.borderGrey};
`;

export const ModalContent = styled(Box)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FilterModal: React.FC<FilterPropTypes> = ({ children, open, handleClose }) => {
  const theme = useTheme();
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContainer>
        <ModalTitle>
          <CustomText color={theme.background.blue} bold={700} fontSize={1.2}>
            FILTERS
          </CustomText>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </ModalTitle>
        <ModalContent>{children}</ModalContent>
      </ModalContainer>
    </Modal>
  );
};
