import { Box, Modal, styled } from '@mui/material';

export const ModalContainer = styled(Modal)`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled(Box)<{ width?: string }>`
  width: ${({ width }) => width || '35%'};
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 10px;

  @media (max-width: 1024px) {
    width: 90%;
  }
`;

export const ModalBanner = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color.divider};
`;
