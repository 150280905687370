import { CircularProgress, Divider, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Loading } from '../../common';
import { LoadingFull } from '../../common/Loading/Loading';

import { CustomModal } from '../../components/CustomModal/CustomModal';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { useCategories } from '../../hooks/useCategories';
import { useProducts } from '../../hooks/useProducts';
import { ProductType } from '../../types/ProductType';
import { AddProductForm } from './AddProductForm';
import { EditProductForm } from './EditProductForm';
import { CustomButton } from './Inventory.style';
import { InventoryDelete } from './InventoryDelete';
import { InventoryListHeader } from './InventoryListHeader';
import { InventoryListItem, InventoryListItemSkeleton } from './InventoryListItem';

export interface ProductCustomType extends ProductType {
  categoryName: string;
}

export const Inventory = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [loadingFavorite, setLoadingFavorite] = useState(false);
  const [showEditModal, setShowEditModal] = useState<ProductCustomType | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<string | null>(null);
  const { ref, inView } = useInView();
  const { categories, isLoading: categoriesIsloading } = useCategories();
  const {
    products,
    isLoading,
    mutationAdd,
    mutationDelete,
    mutationEdit,
    mutationUpdateFavoritesProduct,
    isFetchingNextPage,
    fetchNextPage,
  } = useProducts();

  const closeDelete = () => setShowDeleteModal(null);

  const onSubmitAdd = async (event: any, values: any) => {
    event.preventDefault();
    await mutationAdd.mutateAsync(values);
    setShowAddModal(false);
  };

  const onSubmitEdit = async (event: any, values: any) => {
    event.preventDefault();
    await mutationEdit.mutateAsync(values);
    setShowEditModal(null);
  };

  const onSubmitDelete = async (e: any) => {
    e.preventDefault();
    await mutationDelete.mutateAsync(showDeleteModal);
    closeDelete();
  };

  const addFavorite = async (id: string) => {
    setLoadingFavorite(true);
    const productIsFavorite = products.find(
      (product: any) => product.isFavorite && product.id === id,
    );

    if (productIsFavorite) {
      const sendProducts = products
        .filter((product: any) => product.isFavorite && product.id !== id)
        .map((product: any) => product.id);
      await mutationUpdateFavoritesProduct.mutateAsync(sendProducts);
      return;
    }

    await mutationUpdateFavoritesProduct.mutateAsync([
      id,
      ...products
        .filter((product: any) => product.isFavorite && product.id)
        .map((product: any) => product.id),
    ]);
    setLoadingFavorite(false);
  };

  React.useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <>
      <RouteInfo onClick={() => setShowAddModal(true)} />
      <Divider />

      {showAddModal && (
        <CustomModal
          isOpen={showAddModal}
          onClose={() => setShowAddModal(false)}
          content={() => (
            <AddProductForm
              loading={mutationAdd.isLoading}
              onSubmit={onSubmitAdd}
              categories={categories}
            />
          )}
        />
      )}

      {showEditModal && (
        <CustomModal
          isOpen={!!showEditModal}
          onClose={() => setShowEditModal(null)}
          content={() => (
            <EditProductForm
              onSubmit={onSubmitEdit}
              loading={mutationEdit.isLoading}
              product={showEditModal}
              categories={categories}
            />
          )}
          banner="Edit Product"
        />
      )}

      {showDeleteModal && (
        <CustomModal
          isOpen={!!showDeleteModal}
          onClose={closeDelete}
          content={() => (
            <InventoryDelete
              loading={mutationDelete.isLoading}
              onClose={closeDelete}
              onSubmit={onSubmitDelete}
            />
          )}
          banner="Delete Product"
        />
      )}

      <Stack height="77vh" width="100%" padding="19px" spacing={2} sx={{ overflowY: 'auto' }}>
        <InventoryListHeader />
        {isLoading &&
          [...Array(20)].map((item: any, index: number) => (
            <InventoryListItemSkeleton key={index} />
          ))}
        {products.map((items: any, index: number) => (
          <React.Fragment key={index}>
            {Object.values(items).map((item: any, index: number) => (
              <InventoryListItem
                key={item.id + index}
                name={item.name}
                category={item.categoryName}
                sku={item.sku}
                stock={item.stock}
                price={item.price}
                fav={item.isFavorite}
                onDel={() => setShowDeleteModal(item.id)}
                onClick={() => setShowEditModal(item)}
                setFavoriteItem={() => addFavorite(item.id)}
              />
            ))}
          </React.Fragment>
        ))}
        {loadingFavorite && <LoadingFull />}
        <CustomButton onClick={() => fetchNextPage()} disabled ref={ref}>
          {isFetchingNextPage ? (
            <Stack alignItems="center" direction="row">
              Loading more... <CircularProgress size={16} />
            </Stack>
          ) : (
            'No more items'
          )}
        </CustomButton>
      </Stack>
    </>
  );
};
