import { Box, styled } from '@mui/material';

export const LogoutContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 29px 25px;
  position: relative;
`;
