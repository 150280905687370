import React from 'react';
import { InputFieldProps, CustomInput } from './Input';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { InputWrapper } from './InputWrapper';
import moment from 'moment';

export const DateInput: React.FC<InputFieldProps> = ({
  info,
  id,
  width,
  alignitems,
  padding,
  margin,
  value,
  onChange,
  minDate,
  ...rest
}) => (
  <InputWrapper width={width} alignitems={alignitems} info={info}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        onChange={onChange}
        minDate={minDate}
        renderInput={(params) => (
          <CustomInput
            {...params}
            style={{ textTransform: 'uppercase' }}
            inputProps={{
              ...params.inputProps,
              placeholder: 'DD/MM/YYYY',
            }}
            error={false}
            {...rest}
          />
        )}
      />
    </LocalizationProvider>
  </InputWrapper>
);

export const YearInput: React.FC<InputFieldProps> = ({
  info,
  id,
  width,
  alignitems,
  padding,
  margin,
  value,
  onChange,
  ...rest
}) => (
  <InputWrapper width={width} alignitems={alignitems} info={info}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        onChange={onChange}
        views={['year']}
        renderInput={(params) => (
          <CustomInput
            placeholder="Year"
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: 'Year',
            }}
            error={false}
            {...rest}
          />
        )}
      />
    </LocalizationProvider>
  </InputWrapper>
);

export const MonthInput: React.FC<InputFieldProps> = ({
  info,
  id,
  width,
  alignitems,
  padding,
  margin,
  value,
  onChange,
  ...rest
}) => (
  <InputWrapper width={width} alignitems={alignitems} info={info}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        onChange={onChange}
        views={['month']}
        inputFormat="MMMM"
        renderInput={(params) => (
          <CustomInput
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: 'Month',
            }}
            placeholder="MMM"
            error={false}
            {...rest}
          />
        )}
      />
    </LocalizationProvider>
  </InputWrapper>
);
