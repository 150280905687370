import React from 'react';

export const InfoIcon = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 0C7.38633 0 0 7.38633 0 16.5C0 25.6137 7.38633 33 16.5 33C25.6137 33 33 25.6137 33 16.5C33 7.38633 25.6137 0 16.5 0ZM16.5 8.25C17.6389 8.25 18.5625 9.17361 18.5625 10.3125C18.5625 11.4514 17.6389 12.375 16.5 12.375C15.3611 12.375 14.4375 11.4533 14.4375 10.3125C14.4375 9.17168 15.3592 8.25 16.5 8.25ZM19.0781 24.75H13.9219C13.0711 24.75 12.375 24.0604 12.375 23.2031C12.375 22.3459 13.0679 21.6562 13.9219 21.6562H14.9531V17.5312H14.4375C13.5835 17.5312 12.8906 16.8384 12.8906 15.9844C12.8906 15.1304 13.5867 14.4375 14.4375 14.4375H16.5C17.354 14.4375 18.0469 15.1304 18.0469 15.9844V21.6562H19.0781C19.9321 21.6562 20.625 22.3491 20.625 23.2031C20.625 24.0571 19.9354 24.75 19.0781 24.75Z"
        fill="white"
      />
    </svg>
  );
};
