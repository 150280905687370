import { useTheme } from '@mui/material';
import React, { ChangeEvent } from 'react';

import { CustomButton, Flex } from '../../common';
import { ShadowInputField } from '../../common/Input/Input';
import { ReplyContainer } from './InternalSupportTicket.style';

export interface CreateReplyProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  loading?: boolean;
  handleSubmit?: () => Promise<void>;
  handleExport?: () => Promise<void>;
  exportExternal?: boolean | undefined;
}

export const CreateReply: React.FC<CreateReplyProps> = ({
  value,
  onChange,
  loading,
  handleSubmit,
  handleExport,
  exportExternal,
}) => {
  const theme = useTheme();
  return (
    <ReplyContainer component="form">
      <ShadowInputField
        padding="20px"
        multiline
        name="reply"
        rows={9}
        placeholder="Reply"
        value={value}
        onChange={onChange}
      />
      <Flex flexEnd gap="20px" noFlex>
        {/* {!exportExternal && (
          <CustomButton
            content="Export to Pupil Wallet"
            invert
            bgcolor={theme.color.borderGrey}
            textcolor={theme.palette.common.black}
            loading={loading}
            onClick={handleExport}
          />
        )} */}
        <CustomButton
          loading={loading}
          content="Send Message"
          width="24%"
          type="submit"
          onClick={handleSubmit}
        />
      </Flex>
    </ReplyContainer>
  );
};
