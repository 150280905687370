import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { ListContainer } from './Transactions.style';
import { TransactionsControl } from './TransactionsControl';
import { TransactionsHeader } from './TransactionsHeader';
import { TransactionsItem, TransactionsItemSkeleton } from './TransactionsItem';
import { TransactionTotalPrice } from './TransactionTotalPrice';
import { useTransactions } from '../../hooks/useTransactions';
import { SmallFillter } from './SmallFilter';
import { FetchButton, Flex } from '../../common';

const tabs: { name: string; value: string }[] = [
  { name: 'credits', value: 'wallet' },
  { name: 'School Bill', value: 'schoolBill' },
];

export const Transactions = () => {
  const [value, setValue] = useState(0);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState({
    type: tabs[value].value,
    from: 0,
    to: moment().unix(),
  });
  const { transactions, totalPrice, fetchNextPage, isFetchingNextPage, isLoading } =
    useTransactions(filter);

  useEffect(() => {
    setFilter((prev) => ({ ...prev, type: tabs[value].value }));
  }, [value]);

  const onSave = (values: any) => {
    setFilter((prev) => ({
      ...prev,
      ...values,
    }));
    setFilterOpen(false);
  };

  const changeValue = (event: any, newValue: number) => setValue(newValue);

  return (
    <>
      <RouteInfo />
      <TransactionsControl
        tabs={tabs}
        value={value}
        changeValue={changeValue}
        openFilter={() => setFilterOpen(true)}
      />
      <Flex column>
        <ListContainer>
          <TransactionsHeader />
          {isLoading &&
            [...Array(20)].map((item: any, index: number) => (
              <TransactionsItemSkeleton key={index} />
            ))}
          {transactions &&
            transactions.map((transactions: any) =>
              Object.values(transactions).map((transaction: any, index: number) => (
                <TransactionsItem key={index} {...transaction} />
              )),
            )}

          <FetchButton onView={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
        </ListContainer>
        <TransactionTotalPrice type={tabs[value].name} totalPrice={totalPrice} />
      </Flex>
      <SmallFillter open={filterOpen} handleClose={() => setFilterOpen(false)} onSave={onSave} />
    </>
  );
};
