import { Stack, useTheme } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getAnalytics } from '../../../../api';
import { Flex } from '../../../../common';
import { LineCharts } from '../../../../components/Charts/LineChart';
import { SmallFillter } from '../../../Transactions/SmallFilter';

const initialFilter = {
  from: moment().startOf('year').unix(),
  to: moment().startOf('second').unix(),
};

export const Earnings = () => {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(initialFilter);
  const [value, setValue] = useState([]);
  const [totalValue, setTotalValue] = useState(0);

  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };
  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('earning', {
      from: filter.from,
      to: filter.to,
    });
    setValue(data?.data.data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);
  const sum: any = value.reduce((accumulator, object: any) => {
    return accumulator + object.total;
  }, 0);

  const clearFilter = () => setFilter(initialFilter);
  return (
    <Flex column fullHeight>
      <Flex flex={3}>
        <LineCharts
          data={value}
          title={`Total Earnings: ${sum ? sum / 100 : 0}£`}
          openFilter={() => setFilterOpen(true)}
          initialFilter={initialFilter}
          realFilter={filter}
          clearFilter={clearFilter}
          filter={{
            from: moment.unix(filter.from).format('DD/MMM/YYYY'),
            to: moment.unix(filter.to).format('DD/MMM/YYYY'),
          }}
          loading={loading}
          color={theme.color.graphRed}
        />

        <SmallFillter open={filterOpen} handleClose={() => setFilterOpen(false)} onSave={onSave} />
      </Flex>
      <Flex></Flex>
    </Flex>
  );
};
