import { styled, Box, Typography } from '@mui/material';

export const CircleContainer = styled(Box)<{
  height?: string;
  width?: string;
  margin?: string;
  invert?: boolean;
}>`
  height: ${({ height }) => height || '100%'};
  display: flex;
  flex: 1;
  gap: 20px;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;
  padding: 16px;
  cursor: pointer;
  border-right: ${({ invert, theme }) => !invert && '1px solid ' + theme.color.borderGrey};
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  }

  @media (max-height: 600px) {
    height: 267px;
  }
`;

export const Circle = styled(Box)<{
  height?: string;
  width?: string;
  invert?: boolean;
}>`
  height: ${({ height }) => height || '127px'};
  width: ${({ width }) => width || '127px'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ invert, theme }) =>
    invert ? theme.palette.common.white : theme.background.blue};
`;

export const CommonCircle = styled(Circle)<{ bgcolor?: string }>`
  background: ${({ theme, bgcolor }) => bgcolor || theme.background.blue};
`;

export const Banner = styled(Typography)`
  font-size: 1.1rem;
  text-align: center;
  font-weight: 700;
`;

export const Desc = styled(Typography)`
  font-size: 0.87rem;
  text-align: center;
  word-break: break-word;
`;
