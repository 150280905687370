import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { ROUTES } from '../../constants';
import { InternalSupportContainer } from './InternalSupport.style';
import { InternalSupportControl } from './InternalSupportControl';
import { InternalSupportItem, InternalSupportItemSkeleton } from './InternalSupportItem';
import { InternalSupportItemHeaders } from './InternalSupportItemHeaders';
import { FetchButton } from '../../common/FetchButton';
import { InternalSuppotModal } from './InternalSuppotModal';
import { ListContainer } from '../Transactions/Transactions.style';
import { useInternalSupport } from '../../hooks/useInternalSupport';
import { InternalSupportSend } from './InternalSupportSend';

export const InternalSupport = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const { tickets, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading, refetch } =
    useInternalSupport({});

  return (
    <>
      <RouteInfo banner="Internal Support" onClick={() => setModalOpen(true)} />
      <InternalSupportContainer>
        <ListContainer>
          <InternalSupportItemHeaders />
          {isLoading &&
            [...Array(20)].map((item, index) => <InternalSupportItemSkeleton key={index} />)}
          {!isLoading &&
            tickets?.map((tickets: any) =>
              Object.values(tickets).map((item: any, index: number) => (
                <InternalSupportItem
                  key={index}
                  onClick={() => navigate(`${ROUTES.INTERNAL_SUPPORT_TICKET}/${item.id}`)}
                  {...item}
                />
              )),
            )}
          {!hasNextPage && (
            <FetchButton onView={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
          )}
        </ListContainer>
        <InternalSupportSend
          refetch={refetch}
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      </InternalSupportContainer>
    </>
  );
};
