import { Box, styled } from '@mui/material';

export const NotFoundContainer = styled(Box)`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  a {
    color: ${({ theme }) => theme.color.lightBlue};

    &:visited {
      color: ${({ theme }) => theme.color.lightBlue};
    }
  }
`;
