import React, { useState } from 'react';
import { Flex } from '../../common';

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { useMails } from '../../hooks/useMails';
import { CustomText } from '../../styles';
import { ListContainer } from '../Transactions/Transactions.style';
import { MailControl } from './MailControl';
import { MailCreate } from './MailCreate';
import { MailItem, MailItemSkeleton } from './MailItem';
import { MailListHeader } from './MailListHeader';

export const Mail = () => {
  const [showModal, setShowModal] = useState(false);
  const { mails, setActiveTab, tabs, activeTab, isLoading } = useMails();

  const changeValue = (event: any, newValue: number) => setActiveTab(newValue);
  return (
    <Flex column noFlex>
      <RouteInfo margin="1% 0" onClick={() => setShowModal(true)} />
      {showModal && <MailCreate isOpen={showModal} onClose={() => setShowModal(false)} />}
      <MailControl changeValue={changeValue} tabs={tabs} activeTab={activeTab} />
      <ListContainer>
        <MailListHeader />
        {isLoading &&
          [...Array(20)].map((item: any, index: number) => <MailItemSkeleton key={index} />)}
        {Object.values(mails).length < 1 && (
          <CustomText fontSize={1.3}>Currently there are no mails</CustomText>
        )}
        {mails &&
          mails.map((mails: any) =>
            Object.values(mails).map((mail: any, index: number) => (
              <MailItem
                key={mail.id}
                {...mail}
                user={mail.sentType === 'inbox' ? mail.from : mail.to}
              />
            )),
          )}
      </ListContainer>
    </Flex>
  );
};
