import { Stack } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { ProductAutocomplete } from '../../../components/Autocomplete/ProductAutocomplete';
import { AddOrderProductContainer } from '../Orders.style';
import { AddOrderCircle } from './AddOrderCircle';
import { AddOrderInput } from './AddOrderInput';

interface AddOrderProductProps {
  addProductRow: () => void;
  index: number;
  addProducts: any;
  setAddProducts: any;
  setPrices: Dispatch<SetStateAction<any>>;
}

export const AddOrderProduct: React.FC<AddOrderProductProps> = ({
  addProductRow,
  index,
  addProducts,
  setAddProducts,
  setPrices,
}) => {
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const onChangeProducts = (value: string) => {
    const arr = addProducts.map((item: any, inx: number) => {
      if (inx === index) {
        return {
          ...item,
          productId: value,
        };
      }
    });

    setAddProducts(arr);
  };

  const onChangeQuantity = (e: any) => {
    const arr = addProducts.map((item: any, inx: number) => {
      if (inx === index) {
        setQuantity(parseInt(e.target.value));
        return {
          ...item,
          quantity: parseInt(e.target.value),
        };
      }
    });

    setAddProducts(arr);
  };

  useEffect(() => {
    setPrices((prevState: any) => [
      ...prevState,
      {
        price,
        quantity,
      },
    ]);
  }, [price, quantity]);

  return (
    <AddOrderProductContainer gap={9}>
      <ProductAutocomplete
        onChange={(value: string, price: number) => {
          onChangeProducts(value);
          setPrice(price);
        }}
      />

      <Stack
        width="35%"
        direction="row"
        alignItems="flex-end"
        justifyContent="flex-end"
        spacing={1}
      >
        <AddOrderInput onChange={(e: any) => onChangeQuantity(e)} info="Quantity" />
        <AddOrderCircle onClick={addProductRow} />
      </Stack>
    </AddOrderProductContainer>
  );
};
