import React from 'react';
import { styled, useTheme, Box } from '@mui/material';
import { WhiteContainer, Flex } from '../../common';
import { CustomText } from '../../styles';

const Container = styled(Box)`
  position: fixed;
  bottom: 0;
  margin: 0;
  left: 0;
  width: 100%;
  padding: 0 20px 30px 20px;
  background-color: ${({ theme }) => theme.color.lightGrey};
`;

const TransactionTotalPriceContainer = styled(WhiteContainer)<{
  bgcolor: string;
  color: string;
}>`
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ color }) => color};
`;

export const TransactionTotalPrice: React.FC<{ type: string; totalPrice: number }> = ({
  type,
  totalPrice,
}) => {
  const theme = useTheme();
  const getColor = (type: string) => {
    switch (type) {
      case 'deposits':
        return theme.color.graphYellow;
      case 'school bill':
        return theme.color.graphRed;
      case 'schoolBill':
        return theme.color.graphRed;
      default:
        return theme.background.blue;
    }
  };
  return (
    <Container>
      <TransactionTotalPriceContainer bgcolor={getColor(type)} color={theme.palette.common.white}>
        <Flex>
          <Flex>
            <CustomText bold={700} uppercase color={theme.palette.common.white}>
              {type}
            </CustomText>
          </Flex>
          <Flex flexEnd>
            <CustomText color={theme.palette.common.white}>Total Amount:</CustomText>
            <CustomText bold={700} color={theme.palette.common.white}>
              £{totalPrice ? totalPrice / 100 : 0}
            </CustomText>
          </Flex>
        </Flex>
      </TransactionTotalPriceContainer>
    </Container>
  );
};
