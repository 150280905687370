import { styled, Box } from '@mui/material';
import React from 'react';
import { Footer } from '../svg/Footer';

export const ImageContainer = styled(Box)`
  width: 100%;
  margin-bottom: -10px;
  z-index: 1;
`;

export const FooterShape = () => (
  <ImageContainer>
    <Footer />
  </ImageContainer>
);
