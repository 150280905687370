import { useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { CustomButton, InputField, SelectField, Flex } from '../../common';
import { AttachFile } from '../../common/Input/AttachFile';

import { useForm } from '../../hooks/useForm';
import { useUsers } from '../../hooks/useUsers';
import { MailComposeType } from './MailType';
import { removeEmpty } from '../../helpers';
import { composeMail } from '../../api';
import { AutocompleteInput } from '../../common/Input/AutocompleteInput';
import { CustomText } from '../../styles';
import { CustomModal } from '../../components/CustomModal/CustomModal';
import { useNotification } from '../../hooks/useNotification';
import { useQueryClient } from 'react-query';

interface MailCreateProps {
  isOpen: boolean;
  onClose: () => void;
}

export const MailCreate: React.FC<MailCreateProps> = ({ isOpen, onClose }) => {
  const [loadingCompose, setLoadingCompose] = useState(false);
  const [search, setSearch] = useState<string | null>();
  const [userOption, setUserOptions] = useState<any[]>([]);
  const { notifyError, notify } = useNotification();
  const queryClient = useQueryClient();
  const { users, tabs, setActiveTab, setSelectedGroupId } = useUsers(search);

  const types: any = [
    { name: 'Student', value: 'student' },
    { name: 'Parent', value: 'parent' },
  ];
  const [type, setType] = useState(null);

  const { values, forceChange } = useForm<MailComposeType>({
    groupId: '',
    subject: '',
    body: '',
    vendorId: null,
    paymentOffer: null,
    file: null,
  });

  useEffect(() => {
    const arr: any[] = [];
    users.map((users: any) =>
      Object.values(users).map((user: any, index: number) => arr.push(user)),
    );
    setUserOptions(arr);
  }, [users]);

  const handleSubmint = async () => {
    if (!values.userId || !values.subject || !values.body) {
      notifyError('Please fill all required fields!');
      return;
    } else {
      setLoadingCompose(true);
      try {
        const data = removeEmpty(values);
        if (!!values.paymentOffer) {
          data.type = 'payRequest';
        } else {
          data.type = 'normal';
        }
        const res = await composeMail(data);
        if (res.status === 200) {
          notify({ message: res.data.message });
          queryClient.invalidateQueries(['mails'], {
            refetchActive: true,
          });
          setLoadingCompose(false);
          onClose();
        } else {
          notifyError(res.data.message);
          setLoadingCompose(false);
        }
      } catch (err) {
        setLoadingCompose(false);
      }
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      banner="Compoese"
      content={() => (
        <Flex column p="10px 20">
          <Flex>
            <SelectField
              value={type}
              name="type"
              id="type"
              disabled={values.userId || values.groupId}
              placeholder="Choose type"
              onChange={(e: any) => {
                setType(e.target.value);
                setActiveTab(tabs.indexOf(e.target.value));
              }}
              info="Type"
              options={types}
            />
          </Flex>

          <Flex>
            <AutocompleteInput
              value={values.userId}
              name="studentId"
              id="studentId"
              placeholder="Select"
              info="To *"
              options={userOption}
              disabled={!type || !!values.groupId}
              onInputChange={(e: any) => {
                const delayDebounceFn = setTimeout(() => {
                  if (e.target.value) {
                    setSearch(e.target.value);
                  } else {
                    setSearch(null);
                  }
                }, 400);

                return () => clearTimeout(delayDebounceFn);
              }}
              onChange={(event: any, obj: { uid: string }) => {
                if (obj?.uid) {
                  // setSelectedGroupId(obj.id);
                  forceChange(obj.uid, 'userId');
                } else {
                  // setSelecteduserId('');
                  forceChange(null, 'userId');
                }
              }}
            />

            <AttachFile
              id="attachFile"
              info="Attach File"
              onChange={(obj: any) => {
                forceChange(obj, 'file');
              }}
            >
              <img src="/attach.png" alt="" />
              <CustomText margin="0 0 0 2%">Choose</CustomText>
            </AttachFile>
          </Flex>

          <InputField
            id="subject"
            info="Subject *"
            placeholder="Enter here ..."
            value={values.subject}
            onChange={(e: any) => {
              forceChange(e.target.value, 'subject');
            }}
          />

          <InputField
            id="message"
            info="Message *"
            placeholder="Enter here ..."
            multiline
            rows={6}
            value={values.body}
            onChange={(e: any) => {
              forceChange(e.target.value, 'body');
            }}
          />

          <Flex alignEnd>
            <Flex>
              <InputField
                id="charge"
                info="Charge"
                placeholder="Enter Price"
                type="number"
                disabled={!values.vendorId}
                value={values.paymentOffer && values.paymentOffer / 100}
                onChange={(e: any) => {
                  if (e.target.value <= 0) {
                    forceChange(null, 'paymentOffer');
                  } else {
                    forceChange(e.target.value * 100, 'paymentOffer');
                  }
                }}
              />
            </Flex>
            <Flex>
              <CustomButton
                loading={loadingCompose}
                content="SEND"
                type="submit"
                height="57px"
                width="100%"
                onClick={handleSubmint}
              />
            </Flex>
          </Flex>
          <Flex flexEnd>
            <CustomText>* Required field.</CustomText>
          </Flex>
        </Flex>
      )}
    />
  );
};
