import React from 'react';
import {
  AnalitycsProductsContainer,
  AnalitycsProductsLeftContainer,
  AnalitycsProductsRightContainer,
} from './AnalitycsProducts.style';
import { FourPieChartCard } from './FourPieChartCard';
import { PopularProductsList } from './PopularProductsList';
import { ProductCountCards } from './ProductCountCards';
import { TotalProductsOrdersChart } from './TotalProductsOrdersChart';

export const AnalitycsProducts = () => {
  return (
    <AnalitycsProductsContainer>
      <AnalitycsProductsLeftContainer>
        <FourPieChartCard />
        <TotalProductsOrdersChart />
      </AnalitycsProductsLeftContainer>
      <AnalitycsProductsRightContainer>
        <ProductCountCards />
        <PopularProductsList />
      </AnalitycsProductsRightContainer>
    </AnalitycsProductsContainer>
  );
};
