import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Icon } from '@mui/material';
import React from 'react';
import { Flex } from '../../common';
import { CustomText } from '../../styles';

export const TransactionsHeader = () => (
  <Flex noFlex p="5px 20">
    <Flex flex={15} gap={2}>
      <CustomText bold={700}>Name</CustomText>
    </Flex>
    <Flex flex={15}>
      <CustomText bold={700}>Type</CustomText>
    </Flex>
    <Flex flex={10}>
      <CustomText bold={700}>Date</CustomText>
    </Flex>
    <Flex flex={10}>
      <CustomText bold={700}>Time</CustomText>
    </Flex>
    <Flex flex={3} flexEnd>
      <CustomText bold={700}>Amount</CustomText>
    </Flex>
  </Flex>
);
