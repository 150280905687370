import { Box } from "@mui/material";
import React from "react";

import { CustomText } from "../../../styles";
import { StyledTextField } from "../Orders.style";

interface AddOrderInput {
  info?: string;
  [x:string]: any;
}

export const AddOrderInput: React.FC<AddOrderInput> = ({
  info,
  ...rest
}) => (
  <Box>
    <CustomText bold={700} margin="0 0 8% 0">{info}</CustomText>
    <StyledTextField variant="outlined" {...rest} />
  </Box>
)
