import { Box, styled } from '@mui/material';

export const List = styled('ul')`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0 20px;
  margin: 0;
`;

export const Page = styled(Box)<{ active: boolean; }>`
  height: 41px;
  width: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${({ theme, active }) => active ? 'none' : theme.color.divider};
  color: ${({ active, theme }) => active && theme.palette.common.white};
  background: ${({ active, theme }) => active && theme.background.blue};
`;

export const BtnNP = styled(Box)<{ left: number; }>`
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${({ theme }) => theme.color.divider};
  border-radius: ${({ left }) => !left ? '9px 0 0 9px' : '0 9px 9px 0'};
  transition: all 0.4s;

  &:hover {
    font-weight: 700;
  }
`;
