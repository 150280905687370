import { useState, useEffect } from "react";
import { useQuery } from "react-query";

import { getCategories } from "../api";

export const useCategories = () => {
  const [categories, setCategories] = useState([]);
  const { data, isLoading } = useQuery(
    'categories',
    getCategories,
    { refetchOnMount: true }
  );

  useEffect(() => {
    if(data) {
      setCategories(data.data.data);
    }
  }, [data]);

  return {
    isLoading,
    categories,
    data,
  }
}
