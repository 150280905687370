import { IconButton, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';

import { CustomText } from '../../styles';
import { Favorites } from '../../svg/Favorites';
import { ItemPen } from '../../svg/ItemPen';
import { ItemTrash } from '../../svg/ItemTrash';
import {
  InventoryListContent,
  InventoryListItemContainer,
  LastListContent,
} from './Inventory.style';
import { ProductType } from '../../types/ProductType';

interface InventoryListItemProps extends ProductType {
  fav: boolean;
  category?: string;
  onDel?: () => void;
  onClick?: () => void;
  setFavoriteItem: any;
}

const styles = {
  position: 'absolute',
  top: '50%',
  right: '20px',
  transform: 'translateY(-50%)',
};

export const InventoryListItem: React.FC<InventoryListItemProps> = ({
  name,
  categoryId,
  sku,
  stock,
  price,
  category,
  fav,
  onClick,
  onDel,
  setFavoriteItem,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery('(max-width: 1024px)');

  return (
    <InventoryListItemContainer>
      <InventoryListContent width={19}>
        <Stack height="100%" width="100%" direction="row" alignItems="center" spacing={2}>
          <IconButton size="small" onClick={setFavoriteItem}>
            <Favorites fill={fav ? theme.color.lightBlue : null} />
          </IconButton>
          <CustomText bold={700}>{name}</CustomText>
        </Stack>
      </InventoryListContent>
      <InventoryListContent width={24}>
        <CustomText>{category}</CustomText>
      </InventoryListContent>
      <InventoryListContent width={14}>
        <CustomText>{sku}</CustomText>
      </InventoryListContent>
      <InventoryListContent width={14}>
        <CustomText>{stock}</CustomText>
      </InventoryListContent>
      <InventoryListContent width={20}>
        <CustomText color={theme.palette.success.light}>{price / 100} £</CustomText>
      </InventoryListContent>
      <LastListContent width={8.6}>
        <Stack
          height="100%"
          width="75%"
          direction="row"
          justifyContent={matches ? 'flex-start' : 'flex-end'}
          spacing={4}
        >
          <ItemPen onClick={onClick} />
          <ItemTrash onClick={onDel} />
        </Stack>
      </LastListContent>
    </InventoryListItemContainer>
  );
};

export const InventoryListItemSkeleton = () => {
  const matches = useMediaQuery('(max-width: 1024px)');

  return (
    <InventoryListItemContainer>
      <InventoryListContent width={19}>
        <Stack height="100%" width="100%" direction="row" alignItems="center" spacing={2}>
          <Skeleton width={32} height={32} variant="circular" />

          <Skeleton width={100} height={20} />
        </Stack>
      </InventoryListContent>
      <InventoryListContent width={24}>
        <Skeleton width={110} height={20} />
      </InventoryListContent>
      <InventoryListContent width={14}>
        <Skeleton width={50} height={20} />
      </InventoryListContent>
      <InventoryListContent width={14}>
        <Skeleton width={50} height={20} />
      </InventoryListContent>
      <InventoryListContent width={20}>
        <Skeleton width={40} height={20} />
      </InventoryListContent>
      <LastListContent width={8.6}>
        <Stack
          height="100%"
          width="75%"
          direction="row"
          justifyContent={matches ? 'flex-start' : 'flex-end'}
          spacing={4}
        >
          <Skeleton width={21} height={21} variant="circular" />
          <Skeleton width={21} height={21} variant="circular" />
        </Stack>
      </LastListContent>
    </InventoryListItemContainer>
  );
};
