import { Stack, useTheme } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getAnalytics } from '../../../../api';
import { Flex } from '../../../../common';
import { LineCharts } from '../../../../components/Charts/LineChart';
import { FilterYearMonthUser } from '../../../../components/FilterModals/FilterYearMonthUser';

const initialFilter = {
  year: null,
  month: null,
  user: {
    name: null,
    uid: null,
  },
};

export const TotalTransactionChart = () => {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(initialFilter);
  const [value, setValue] = useState([]);

  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };
  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('transactions/total', {
      year: filter.year,
      month: filter.month,
      userId: filter.user.uid,
    });
    setValue(data?.data.data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);

  const sum: any = value.reduce((accumulator, object: any) => {
    return accumulator + object.total;
  }, 0);
  const clearFilter = () => setFilter(initialFilter);
  return (
    <Flex column fullHeight>
      <LineCharts
        data={value}
        filter={{
          year: filter.year,
          month: filter.month,
          user: filter.user.name,
        }}
        initialFilter={initialFilter}
        realFilter={filter}
        clearFilter={clearFilter}
        title={`Total Transactions: ${sum ? sum / 100 : 0}$`}
        openFilter={() => setFilterOpen(true)}
        loading={loading}
        color={theme.color.graphRed}
      />

      <FilterYearMonthUser
        open={filterOpen}
        handleClose={() => setFilterOpen(false)}
        onSave={onSave}
      />
    </Flex>
  );
};
