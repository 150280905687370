import { styled, Box } from '@mui/material';
import { WhiteContainer } from '../../common';

export const ChartContainer = styled(WhiteContainer)`
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  height: 100%;
`;

export const ChartContainerContent = styled(Box)`
  padding: 20px;
  width: 100%;
  flex: 1;
`;
