import React from 'react';
import { Outlet } from 'react-router-dom';

import { AnalyticLayout } from '../../components/Layout/AnalyticLayout';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { AnalyticTabs } from './AnalyticTabs';

export const Analytics = () => (
  <>
    <RouteInfo
      banner="Analytics"
      description="View meaningful data for effective decision making"
      smImage="/analytics-sm.png"
    />
    <AnalyticTabs />
    <AnalyticLayout>
      <Outlet />
    </AnalyticLayout>
  </>
);
