import { equalTo, getDatabase, onValue, orderByChild, query, ref } from 'firebase/database';

import { app } from '../config/db';

export const dbReadOnce = (
  {
    refPath,
    orderByChildPath,
    eq,
  }: {
    refPath?: any;
    orderByChildPath?: any;
    eq?: any;
  },
  callback: any,
) => {
  const db = getDatabase(app);
  const queryConstraints = [];
  if (orderByChildPath) queryConstraints.push(orderByChild(orderByChildPath));
  if (eq) queryConstraints.push(equalTo(eq));

  const q = query(ref(db, refPath), ...queryConstraints);
  onValue(
    q,
    (snap) => {
      callback(snap);
    },
    { onlyOnce: true },
  );
};

export const dbRead = (
  {
    refPath,
    orderByChildPath,
    eq,
  }: {
    refPath?: any;
    orderByChildPath?: any;
    eq?: any;
  },
  callback: any,
) => {
  const db = getDatabase(app);
  const queryConstraints = [];
  if (orderByChildPath) queryConstraints.push(orderByChild(orderByChildPath));
  if (eq) queryConstraints.push(equalTo(eq));

  const q = query(ref(db, refPath), ...queryConstraints);
  onValue(q, (snap) => {
    callback(snap);
  });
};
