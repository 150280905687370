import { MenuItem, Stack } from '@mui/material';
import React from 'react';

import { CustomButton, InputField, SelectField } from '../../common';
import { useForm } from '../../hooks/useForm';
import { useNotification } from '../../hooks/useNotification';
import { ProductCustomType } from './Inventory';

interface EditProductFormProps {
  onSubmit: (event: any, values: any) => void;
  product: ProductCustomType;
  categories: any;
  loading: boolean;
}

export const EditProductForm: React.FC<EditProductFormProps> = ({
  onSubmit,
  product,
  categories,
  loading,
}) => {
  const index = categories.findIndex((item: any) => item.name === product.categoryName);
  const { notifyError } = useNotification();
  const { values, onChange } = useForm<any>({
    name: product.name,
    sku: product.sku,
    stock: product.stock,
    price: product.price / 100,
    categoryId: categories[index].id,
  });
  console.log(values);

  return (
    <Stack
      component="form"
      onSubmit={(event: any) => {
        if (Object.values(values).some((value: any) => !value)) {
          notifyError('Please fill in all fields!');
          return;
        }
        onSubmit(event, {
          ...values,
          productId: product.id,
          stock: parseInt(values.stock),
          price: values.price * 100,
        });
      }}
      height="100%"
      width="100%"
      padding="10px 20px"
      direction="column"
      alignItems="center"
      justifyContent="space-evenly"
    >
      <InputField
        id="name"
        info="Product Name"
        name="name"
        placeholder="Enter here..."
        value={values.name}
        onChange={onChange}
      />
      <SelectField
        id="categoryId"
        info="Category Name"
        name="categoryId"
        value={values.categoryId}
        onChange={onChange}
      >
        {categories.map((category: any) => (
          <MenuItem
            key={category.id}
            selected={category.id === (values.categoryId || product.categoryId)}
            value={category.id}
          >
            {category.name}
          </MenuItem>
        ))}
      </SelectField>
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={4}
      >
        <InputField
          id="sku"
          info="SKU"
          name="sku"
          placeholder="Enter here..."
          value={values.sku}
          onChange={onChange}
        />
        <InputField
          id="stock"
          info="Stock"
          name="stock"
          placeholder="Enter here..."
          value={values.stock}
          onChange={onChange}
        />
        <InputField
          id="price"
          type="number"
          info="Price"
          name="price"
          value={values.price}
          onChange={onChange}
        />
      </Stack>
      <CustomButton loading={loading} content="Submit" width="100%" type="submit" margin="4% 0" />
    </Stack>
  );
};
