import { Box, styled } from '@mui/material';

export const SectionHeaderContainer = styled(Box)`
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border: 1px solid ${({ theme }) => theme.color.divider};
  border-left: none;
  border-right: none;
  margin: 0 auto;
  position: relative;

  button {
    color: ${({ theme }) => theme.palette.common.black};
  }

  @media(max-width: 1024px) {
    flex-direction: column;

    div {
      width: 100%;
    }
  }
`;
