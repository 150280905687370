import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CustomText } from '../../styles';
import { analyticLinks, AnalyticsLink } from './Analytics.constants';
import { AnalyticLinksContainer, LinkCircle, TabItem } from './Analytics.style';

export const AnalyticTabs = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <AnalyticLinksContainer>
      {analyticLinks.map(({ name, link, image }: AnalyticsLink) => (
        <TabItem key={name} active={link === pathname} gap="10px" onClick={() => navigate(link)}>
          <LinkCircle active={link === pathname}>
            <img src={image} alt="" />
          </LinkCircle>
          <CustomText bold={700}>{name}</CustomText>
        </TabItem>
      ))}
    </AnalyticLinksContainer>
  );
};
