import React from "react";

import { CustomText, TableContent, TableHeader } from "../../styles";

export const OrdersHeader = () => (
  <TableHeader>
    <TableContent width={28.75}>
      <CustomText bold={700}>Suplier Name</CustomText>
    </TableContent>
    <TableContent width={17}>
      <CustomText bold={700}>Order Date</CustomText>
    </TableContent>
    <TableContent width={23.75}>
      <CustomText bold={700}>Order Time</CustomText>
    </TableContent>
    <TableContent width={24}>
      <CustomText bold={700}>Total Order Price</CustomText>
    </TableContent>
    <TableContent width={20}>
      <CustomText bold={700}>Status</CustomText>
    </TableContent>
  </TableHeader>
)
