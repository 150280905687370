import { Box, styled } from '@mui/material';

export const ChartInfoContainer = styled(Box)<{
  noBorder?: boolean;
  margin?: string;
  padding?: string;
  last?: boolean;
}>`
  display: flex;
  width: 100%;
  padding: ${({ padding }) => (padding ? padding : '20px')};
  justify-content: space-between;
  border-bottom: 1px solid ${({ last, theme }) => (!last ? theme.color.borderGrey : 'none')};
  margin: ${({ margin }) => margin || '0'};

  @media (max-width: 768px) {
    p {
      font-size: 1.1rem;
    }
  }
`;

export const DescContainer = styled(Box)<{ gap?: number; margin?: string }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? `${gap}px` : '0')};
  margin: ${({ margin }) => margin || '0'};
`;

export const StyledDot = styled(Box)<{ height?: string; width?: string; bgcolor: string }>`
  height: ${({ height }) => height || '16px'};
  width: ${({ width }) => width || '16px'};
  border-radius: 50%;
  background: #${({ bgcolor }) => bgcolor};
`;
