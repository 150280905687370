import { useTheme } from '@mui/material';
import React from 'react';

import { LayoutContainer } from './Layout.style';

interface LayoutProps {
  bgcolor?: string;
  padding?: string;
  noScroll?: string;
}

export const Layout: React.FC<LayoutProps> = ({ children, padding, noScroll, bgcolor }) => {
  const theme = useTheme();

  return (
    <LayoutContainer
      bgcolor={bgcolor || theme.color.lightGrey}
      padding={padding}
      noScroll={noScroll}
    >
      {children}
    </LayoutContainer>
  );
};
