import { useTheme } from '@mui/material';
import React from 'react';
import { Flex, WhiteContainer } from '../../common';
import { SmallCircle } from '../../components/RouteInfo/RouteInfo.style';
import { CustomText } from '../../styles';
import { OverviewBannerItem } from './Overview.style';
import { TopProductCard } from './TopProductCard';

interface TopProductPrps {
  boughtProducts: {
    totalCount: number;
    totalPrice: number;
    totalOrders: number;
    product: {
      id: string;
      name: string;
      quantity: number;
      price: number;
      categoryName: string;
    };
  }[];
  lowProductsCount: number;
  todayTransactionCount: number;
}

export const TopProducts: React.FC<TopProductPrps> = ({
  boughtProducts,
  lowProductsCount,
  todayTransactionCount,
}) => {
  const theme = useTheme();
  return (
    <>
      <WhiteContainer padding="0px">
        <Flex column fullHeight gap="0px">
          <OverviewBannerItem>
            <CustomText fontSize={1.2} bold={700}>
              Top Selling Products
            </CustomText>
            <CustomText>Today</CustomText>
          </OverviewBannerItem>
          <Flex p="20" fullHeight>
            {boughtProducts?.length > 0 &&
              boughtProducts.map((product: any, index) => (
                <TopProductCard key={index} {...product} />
              ))}
          </Flex>
        </Flex>
      </WhiteContainer>
      <Flex noFlex column fullHeight>
        <WhiteContainer padding=" 20px 50px ">
          <Flex column>
            <SmallCircle color={theme.color.graphYellow}>
              <CustomText color="white" fontSize={1.5} bold={700}>
                {todayTransactionCount}
              </CustomText>
            </SmallCircle>
            <CustomText>Transactions</CustomText>
            <CustomText>Today</CustomText>
          </Flex>
        </WhiteContainer>
        <WhiteContainer padding="20px 40px ">
          <Flex column contectCenter>
            <SmallCircle color={theme.color.graphRed}>
              <CustomText color="white" fontSize={1.5} bold={700}>
                {lowProductsCount}
              </CustomText>
            </SmallCircle>
            <CustomText>Products</CustomText>
            <CustomText>Low on stock</CustomText>
          </Flex>
        </WhiteContainer>
      </Flex>
    </>
  );
};
