import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getStudents } from '../api';

export const useStudents = () => {
  const [students, setStudents] = useState<any>([]);
  const { data } = useQuery('students', getStudents);

  useEffect(() => {
    if(data) {
      setStudents(
        data?.data.data.users.map((item: any) => ({ id: item.uid, name: item.name }))
      );
    }
  }, [data]);

  return {
    students,
  }
}
