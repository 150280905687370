import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getOverview } from '../api';

export const useOverview = () => {
  const [overview, setOverview] = useState<any>([]);
  const { data } = useQuery('students', getOverview);

  useEffect(() => {
    if (data) {
      setOverview(data.data.data);
    }
  }, [data]);

  return {
    overview,
  };
};
