import { Box, Button, styled } from '@mui/material';

export const InventoryListItemContainer = styled(Box)<{ noHover?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  position: relative;

  &:hover {
    outline: ${({ noHover, theme }) => noHover ? 'none' : `1px solid ${theme.color.lightBlue}`};
  }

  @media(max-width: 1024px) {
    height: 100%;
    justify-content: flex-start;
    flex-direction: column;
    gap: 4px;
  }
`;

export const InventoryListContent = styled(Box)<{ width: number; justifyContent?: string; }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  flex: 0 0 ${({ width }) => `${width}%`};

  @media(max-width: 1024px) {
    width: 100%;
  }
`;

export const LastListContent = styled(InventoryListContent)`
  @media(max-width: 768px) {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 100%;
    left: 100%;
    transform: translate(-25%, -50%);
    z-index: 1;
  }
`;

export const InventoryListHeaderContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;

  @media(max-width: 1024px) {
    display: none;
  }
`;

export const CustomButton = styled(Button)`
  background-color: #fff;
`;
