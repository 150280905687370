import React from 'react';

export const Footer = () => {
  return (
    <svg
      width="100%"
      height="139"
      viewBox={`0 0 1680 139`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <g filter="url(#filter0_d_829_1858)">
        <path
          d="M1680 139L1680 69.1774L1680 32.3396C1680 32.3396 1420.07 70.9526 1145 69.1772C869.928 67.4019 733.132 11.9959 471.5 5.33844C209.868 -1.31899 1.04886e-05 19.0245 1.04886e-05 19.0245L6.10409e-06 69.1772L0 139L1680 139Z"
          fill="url(#paint0_linear_829_1858)"
          vectorEffect="non-scaling-stroke"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_829_1858"
          x="-10"
          y="0"
          width="1700"
          height="155"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_829_1858" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_829_1858"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_829_1858"
          x1="779.409"
          y1="-54.0984"
          x2="751.209"
          y2="237.965"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="1" stopColor="#0C84FC" />
        </linearGradient>
      </defs>
    </svg>
  );
};
