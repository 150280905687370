import { styled, Box, Typography } from '@mui/material';
import { Circle } from '../../common';

export const RouteInfoContainer = styled(Box)<{ margin?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  margin: ${({ margin }) => margin || '0'};

  h5,
  p {
    margin: 0 2%;

    &:nth-of-type(1) {
      font-weight: 600;
    }
    &:nth-of-type(2) {
      font-weight: 244;
    }
  }

  h6 {
    font-size: 0.9rem;
    font-weight: 300;
    margin: 0 2%;
  }

  @media (max-width: 768px) {
    height: 100px;
    align-items: space-between;
    justify-content: center;
    flex-direction: column;
    z-index: 4;

    h5 {
      font-size: 0.8rem;
    }

    h6 {
      font-size: 0.75rem;
    }

    p {
      font-size: 0.71rem;
    }
  }
`;

export const TextContainer = styled(Box)<{ right?: string }>`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: ${({ right }) => right || 'flex-start'};

  @media (max-width: 768px) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 2%;
  }
`;

export const PageNameContainer = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SmallCircle = styled(Circle)<{ limit?: boolean; color?: string; size?: number }>`
  max-width: ${({ limit, size }) => (limit ? 'none' : size ? size : 61)}px;
  max-height: ${({ size }) => (size ? size : 61)}px;
  min-height: ${({ size }) => (size ? size : 61)}px;
  overflow: hidden;
  background-color: ${({ color, theme }) => (color ? color : theme.background.blue)};

  svg {
    max-width: 50%;
    max-height: 50%;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const InfoContainer = styled(Box)`
  height: 10%;
  display: flex;
  flex-direction: column;
  flex: 0 0 75%;

  @media (max-width: 1024px) {
    height: 100%;
    justify-content: center;
  }
`;

export const LinkPaths = styled(Typography)`
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.grey[800]};

    &:hover {
      text-decoration: underline;
    }
  }
`;
