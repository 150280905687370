import { Box, styled, Typography } from '@mui/material';

export const CustomText = styled(Typography)<{
  fontSize?: number;
  color?: string;
  bold?: number;
  margin?: string;
  uppercase?: boolean;
  textCenter?: boolean;
}>`
  font-size: ${({ fontSize }) => `${fontSize}rem` || '1rem'};
  font-weight: ${({ bold }) => bold || 400};
  color: ${({ color, theme }) => color || theme.palette.common.black};
  margin: ${({ margin }) => margin || '0'};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  text-align: ${({ textCenter }) => (textCenter ? 'center' : 'start')};
`;

export const TableHeader = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const TableItem = styled(Box)<{ width?: string; noHover?: boolean }>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  position: relative;

  &:hover {
    outline: ${({ noHover, theme }) => (noHover ? 'none' : `1px solid ${theme.color.lightBlue}`)};
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 4px;
  }
`;

export const TableContent = styled(Box)<{ width: number; justifyContent?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  flex: 0 0 ${({ width }) => `${width}%`};

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const GraphBannerItem = styled(Box)`
  height: 23%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 18px 25px;
  position: relative;

  &::after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.color.divider};
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;

export const GraphContent = styled(Box)`
  height: 77%;
  width: 100%;
  padding: 18px 25px 18px 0;
`;
