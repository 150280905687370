import React from 'react';
import { CustomText } from '../../styles';
import { MailListItemContent } from '../Mail/Mail.style';
import { InternalSupportHeadersContainer } from './InternalSupport.style';

export const InternalSupportItemHeaders = () => (
  <InternalSupportHeadersContainer>
    <MailListItemContent flex={1.5} />
    <MailListItemContent flex={10}>
      <CustomText bold={700}>Name</CustomText>
    </MailListItemContent>
    <MailListItemContent flex={10}>
      <CustomText bold={700}>Subject</CustomText>
    </MailListItemContent>
    <MailListItemContent flex={5}>
      <CustomText bold={700}>Date</CustomText>
    </MailListItemContent>
    <MailListItemContent flex={5}>
      <CustomText bold={700}>Time</CustomText>
    </MailListItemContent>
    <MailListItemContent flex={5}>
      <CustomText bold={700}>Agent</CustomText>
    </MailListItemContent>
    <MailListItemContent flex={5}>
      <CustomText bold={700}>Resolved</CustomText>
    </MailListItemContent>
    <MailListItemContent flex={1} />
  </InternalSupportHeadersContainer>
);
