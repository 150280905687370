import { Box, styled } from "@mui/material";
import React from "react";

const CollegeHatContainer = styled(Box)`
  height: 42px;
  width: 41px;
  position: relative;

  svg {
    position: absolute;
  }

  svg:nth-of-type(2) {
    transform: translate(15%, 67%);
  }
`;

const CollegeHatOne = () => (
  <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.7358 35.1677L40.051 31.9709C40.7206 31.3985 41.1526 30.5453 41.1526 29.5949C41.1526 28.2017 40.2346 27.0245 38.9818 26.6141V10.3277L40.6666 9.61486L20.827 0.79126L0.328613 9.28006L20.8162 17.8553L37.135 10.8893V26.5817C35.839 26.9597 34.8886 28.1693 34.8886 29.5949C34.8886 30.5453 35.3206 31.3985 35.9902 31.9709L34.3054 35.1677L36.3898 35.1785L37.6858 32.7053C37.7938 32.7161 37.9126 32.7269 38.0206 32.7269C38.1286 32.7269 38.2474 32.7161 38.3554 32.7053L39.6514 35.1785L41.7358 35.1677ZM20.8054 15.3929L5.82581 9.24766L20.8162 3.26446L35.2774 9.33406L20.8054 15.3929ZM38.0206 28.2989C38.7334 28.2989 39.3058 28.8713 39.3058 29.5841C39.3058 30.2969 38.7334 30.8693 38.0206 30.8693C37.3078 30.8693 36.7354 30.2969 36.7354 29.5841C36.7354 28.8713 37.3078 28.2989 38.0206 28.2989Z" fill="white"/>
  </svg>
)

const CollegeHatTwo = () => (
  <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.9796 0.934789L15.8272 6.08638L0.674811 0.934789L0.491211 0.848389V12.0264L15.0928 19.3812L15.8164 19.7376L16.7884 19.2516L31.1416 12.0264V7.61998V5.17918V0.848389L30.9796 0.934789ZM14.8552 17.07L2.44601 10.806V3.98039L14.8552 10.1148V17.07ZM15.8272 8.41918L6.88481 4.00199L15.8272 7.03678L24.7696 4.00199L15.8272 8.41918ZM29.2084 8.46238V10.806L16.7992 17.07V10.1148L29.2084 3.98039V6.10798L27.2644 7.03678V9.30478L29.2084 8.46238Z" fill="white"/>
  </svg>
)

export const CollegeHat = () => (
  <CollegeHatContainer>
    <CollegeHatOne />
    <CollegeHatTwo />
  </CollegeHatContainer>
);
