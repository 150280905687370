import { Stack, useTheme } from '@mui/material';
import React from 'react';

import { CustomButton, Flex } from '../../common';
import { CustomText } from '../../styles';

interface InventoryDeleteProps {
  onSubmit: (e: any) => void;
  onClose: () => void;
  loading: boolean;
}

export const InventoryDelete: React.FC<InventoryDeleteProps> = ({ onSubmit, onClose, loading }) => {
  const theme = useTheme();

  return (
    <Stack
      component="form"
      height="100%"
      width="100%"
      padding="29px 25px"
      direction="column"
      justifyContent="space-around"
      spacing={10}
      onSubmit={onSubmit}
    >
      <CustomText fontSize={1.1} bold={700}>
        Are you sure want to delete product?
      </CustomText>
      <Flex>
        <CustomButton
          content="Cancel"
          bgcolor={theme.palette.common.white}
          invert
          loading={loading}
          width="100%"
          onClick={onClose}
        />
        <CustomButton
          content="Confirm"
          loading={loading}
          width="100%"
          bgcolor={theme.palette.error.light}
          type="submit"
        />
      </Flex>
    </Stack>
  );
};
