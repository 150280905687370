import { Box, Stack, styled } from '@mui/material';

export const AnalyticLinksContainer = styled(Box)<{ gap?: string }>`
  height: 75px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: ${({ gap }) => gap || '10px'};
  padding: 4px 20px;

  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
    position: relative;
  }
`;

export const TabItem = styled(Box)<{ active: boolean; gap?: string }>`
  height: 100%;
  flex: 0 0 16%;
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap || '0'};
  padding: 14px;
  border: ${({ active }) => !active && '0.5px solid #C1C1C1'};
  border-radius: 10px;
  box-shadow: ${({ active }) => active && '0px 4px 10px rgba(0, 0, 0, 0.15)'};
  cursor: pointer;
  background-color: ${({ active, theme }) => active && theme.palette.common.white};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LinkCircle = styled(Box)<{ active: boolean }>`
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ active, theme }) => (active ? theme.background.blue : '#BDBDBD')};
  border-radius: 50%;

  img {
    height: 23px;
    width: 23px;
    object-fit: contain;
  }
`;

export const DataContainer = styled(Stack)<{ width?: string }>`
  height: 50%;
  width: ${({ width }) => width || '100%'};

  @media (max-width: 768px) {
    height: 50vh;
    width: 100%;
    flex-direction: column;
  }
`;

export const GraphContainer = styled(Box)<{ width?: string; padding?: string }>`
  height: 100%;
  flex: 0 0 ${({ width }) => width || '100%'};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: ${({ padding }) => padding};
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 10px;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 2% !important;
    margin-left: 0 !important;
  }
`;
