import { CircularProgress, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getAnalytics } from '../../../../api';
import { Flex } from '../../../../common';
import { ChartContainer, ChartContainerContent } from '../../../../components/Charts/Chart.style';
import { ChartTitle } from '../../../../components/Charts/ChartTitle';
import { FilterYearMonth } from '../../../../components/FilterModals/FilterYearMonth';
import { CustomText } from '../../../../styles';
import { FourPieChartCardContent } from './AnalitycsProducts.style';
import { SmallPieChart } from './SmallPieChart';

const initialFilter = {
  year: null,
  month: null,
};

export const FourPieChartCard = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState(initialFilter);
  const [value, setValue] = useState({ categories: [], totalCount: 0 });

  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };
  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('products/popular-category', {
      year: filter.year,
      month: filter.month,
    });
    setValue(data?.data.data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);

  const clearFilter = () => setFilter(initialFilter);
  return (
    <ChartContainer>
      <ChartTitle
        title="Popular Category"
        initialFilter={initialFilter}
        realFilter={filter}
        clearFilter={clearFilter}
        onClick={() => setFilterOpen(true)}
        filter={filter}
      />
      <ChartContainerContent>
        {loading ? (
          <Flex fullHeight contectCenter>
            <CircularProgress size="4rem" />
            <CustomText fontSize={1.4} color={theme.background.blue}>
              Loading...
            </CustomText>
          </Flex>
        ) : (
          <FourPieChartCardContent>
            {value?.categories?.map((category: any, index: number) => (
              <SmallPieChart key={index} {...category} totalSum={value.totalCount} />
            ))}
          </FourPieChartCardContent>
        )}
      </ChartContainerContent>
      <FilterYearMonth open={filterOpen} onSave={onSave} handleClose={() => setFilterOpen(false)} />
    </ChartContainer>
  );
};
