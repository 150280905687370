import { Alert, styled } from "@mui/material";

export const StyledMuiAlert = styled(Alert)`
  align-items: center;
  z-index: 999999;

  & .MuiAlert-action {
    padding-top: 0;
  }
`;
