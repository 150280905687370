import React from "react";

interface FavoritesProps {
  fill?: string | null;
}

export const Favorites: React.FC<FavoritesProps> = ({
  fill
}) => (
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.1 20.98C5.9344 21.067 5.74768 21.1059 5.56108 21.0922C5.37448 21.0784 5.19548 21.0126 5.04443 20.9022C4.89338 20.7917 4.77634 20.6411 4.70662 20.4675C4.63689 20.2939 4.61728 20.1042 4.65 19.92L5.68 13.89L1.3 9.62996C1.165 9.49951 1.06944 9.33369 1.02426 9.15148C0.979084 8.96926 0.986109 8.77801 1.04454 8.5996C1.10296 8.42119 1.21043 8.26283 1.35464 8.14264C1.49885 8.02245 1.67399 7.94528 1.86 7.91996L7.91 7.03996L10.61 1.55996C10.6919 1.39089 10.8197 1.2483 10.9789 1.14853C11.1381 1.04876 11.3221 0.99585 11.51 0.99585C11.6979 0.99585 11.8819 1.04876 12.0411 1.14853C12.2003 1.2483 12.3281 1.39089 12.41 1.55996L15.11 7.03996L21.17 7.91996C21.3532 7.94822 21.5248 8.02681 21.6659 8.14697C21.807 8.26713 21.9119 8.42413 21.969 8.60044C22.026 8.77676 22.0329 8.96546 21.989 9.14548C21.945 9.32551 21.8519 9.48978 21.72 9.61996L17.34 13.89L18.38 19.92C18.4141 20.1055 18.395 20.297 18.325 20.4722C18.2549 20.6473 18.1367 20.7991 17.984 20.91C17.8313 21.0208 17.6504 21.0862 17.4621 21.0986C17.2738 21.111 17.0859 21.0699 16.92 20.98L11.52 18.13L6.1 20.98ZM11.05 16.11C11.1934 16.0346 11.353 15.9953 11.515 15.9953C11.677 15.9953 11.8366 16.0346 11.98 16.11L16.06 18.26L15.28 13.71C15.2538 13.5508 15.2664 13.3876 15.3169 13.2343C15.3674 13.0811 15.4543 12.9424 15.57 12.83L18.87 9.60996L14.31 8.93996C14.149 8.91845 13.9956 8.85804 13.8632 8.76395C13.7308 8.66986 13.6233 8.54493 13.55 8.39996L11.51 4.25996L9.47 8.39996C9.39765 8.54366 9.29169 8.66779 9.16113 8.7618C9.03056 8.85581 8.87924 8.91692 8.72 8.93996L4.15 9.60996L7.45 12.83C7.56753 12.9414 7.65624 13.0797 7.70849 13.233C7.76075 13.3863 7.77499 13.5499 7.75 13.71L6.96 18.26L11.05 16.11Z" fill={fill ? fill : "#979797"}/>
  </svg>
);
