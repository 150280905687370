import { Typography, capitalize, useMediaQuery } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { CustomButton, Flex } from '../../common';
import { decodeUrl, formatUrl, makeLink } from '../../helpers';
import { Links, links } from './RouteInfo.constants';
import { LinkPaths, RouteInfoContainer, TextContainer } from './RouteInfo.style';
import { RouteInfoDetails } from './RouteInfoDetails';

interface RouteInfoProps {
  smImage?: string;
  margin?: string;
  onClick?: any;
  banner?: string;
  description?: string;
  redirect?: () => void;
  noButton?: boolean;
  lastRoute?: string;
  noLastRoute?: boolean;
}

export const RouteInfo: React.FC<RouteInfoProps> = ({
  smImage,
  margin,
  onClick,
  banner,
  description,
  redirect,
  noButton,
  lastRoute,
  noLastRoute,
}) => {
  const { pathname } = useLocation();
  const link = links.find((item: Links) => {
    return item?.link && item.link.replace('/', '') === pathname.split('/')[1];
  });
  const paths = makeLink(formatUrl(pathname)).filter((path) => path.length);
  const matches = useMediaQuery('(max-width: 768px)');

  return (
    <RouteInfoContainer margin={margin}>
      {link ? (
        <TextContainer onClick={redirect}>
          {pathname.length < 2 ? (
            <>
              <Typography variant="h5">Vendor Console</Typography>
              {!matches && <Typography variant="h4">|</Typography>}
              <Typography variant="h5">Bradfield College</Typography>
            </>
          ) : (
            <RouteInfoDetails
              pageName={link?.banner || capitalize(decodeUrl(pathname))}
              description={!link?.noDesc ? link?.descriptionText : ''}
              image={link?.image}
            />
          )}
        </TextContainer>
      ) : (
        <Flex gap="5px">
          <Typography variant="h5">Vendor Console</Typography>
          {/* <Typography variant="h4">|</Typography> */}
          {/* <Typography variant="h5">Bradfield College</Typography> */}
        </Flex>
      )}

      <TextContainer right="flex-end">
        <LinkPaths variant="h6">
          <Link to="/">Pupil wallet / Admin Console</Link>
          {paths.map((path, index) => {
            if (noLastRoute && index >= paths.length - 1) {
              return null;
            }
            if (lastRoute && index >= paths.length - 1) {
              return (
                <Link to={``} key={index}>
                  {' '}
                  / {lastRoute}
                </Link>
              );
            }
            return (
              <Link to={''} key={index}>
                {' '}
                / {formatUrl(path)}
              </Link>
            );
          })}
        </LinkPaths>
        {link?.btnOption && !noButton && pathname.length > 2 && (
          <CustomButton content={link.btnOption} width="27%" onClick={onClick} />
        )}
      </TextContainer>
    </RouteInfoContainer>
  );
};
