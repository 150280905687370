import { Divider } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material';
import { Flex, WhiteContainer } from '../../common';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { CustomText } from '../../styles';
import { settingsLinks } from './SettingsLinks';
import { Logout } from '../../svg/Logout';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const LinkContainer = styled(Link)`
  width: 100%;
`;

export const Settings = () => {
  const { logout } = useAuth();
  return (
    <>
      <RouteInfo />
      <Divider />
      <Flex column p={20}>
        {settingsLinks.map((link, index) => (
          <Flex noFlex key={index}>
            <LinkContainer to={link.link}>
              <Flex>
                <WhiteContainer>
                  <Flex>
                    {link.icon}
                    <CustomText>{link.name}</CustomText>
                  </Flex>
                </WhiteContainer>
              </Flex>
            </LinkContainer>
          </Flex>
        ))}
        <Flex noFlex key="logout" onClick={logout}>
          <WhiteContainer>
            <Flex>
              <Logout />
              <CustomText>Logout</CustomText>
            </Flex>
          </WhiteContainer>
        </Flex>
      </Flex>
    </>
  );
};
