import React from 'react';

export const AnalyticIcon: React.FC<{ color?: string }> = ({ color }) => (
  <svg width="61" height="55" viewBox="0 0 61 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7705 54.875H3.12467C1.45176 54.875 0.0830078 53.5063 0.0830078 51.8333V21.4167C0.0830078 19.7438 1.45176 18.375 3.12467 18.375H13.7705C15.4434 18.375 16.8122 19.7438 16.8122 21.4167V51.8333C16.8122 53.5063 15.4434 54.875 13.7705 54.875ZM35.8226 0.125H25.1768C23.5038 0.125 22.1351 1.49375 22.1351 3.16667V51.8333C22.1351 53.5063 23.5038 54.875 25.1768 54.875H35.8226C37.4955 54.875 38.8643 53.5063 38.8643 51.8333V3.16667C38.8643 1.49375 37.4955 0.125 35.8226 0.125ZM57.8747 24.4583H47.2288C45.5559 24.4583 44.1872 25.8271 44.1872 27.5V51.8333C44.1872 53.5063 45.5559 54.875 47.2288 54.875H57.8747C59.5476 54.875 60.9163 53.5063 60.9163 51.8333V27.5C60.9163 25.8271 59.5476 24.4583 57.8747 24.4583Z"
      fill={color ? color : '#0C84FC'}
    />
  </svg>
);
