import { Box, Stack, styled } from '@mui/material';
import React from 'react';

import { CustomText } from '../styles';

const PieInfoContainer = styled(Box)<{ isLast?: boolean }>`
  width: 100%;
  padding: 9px 0;
  border-bottom: ${({ isLast, theme }) => (isLast ? `1px solid ${theme.color.divider}` : 'none')};
`;

const ColorDot = styled(Box)<{ bgcolor: string }>`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: ${({ bgcolor }) => bgcolor};
`;

interface PieInfoProps {
  name: string;
  color: string;
  isLast: boolean;
  cnt: number;
  percentage: number;
}

export const PieInfo: React.FC<PieInfoProps> = ({ name, color, isLast, cnt, percentage }) => (
  <PieInfoContainer isLast={isLast}>
    <Stack direction="row" alignItems="center" spacing={2}>
      <ColorDot bgcolor={color} />
      <CustomText bold={700}>{name}</CustomText>
    </Stack>
    <Stack direction="row" spacing={2} margin="0 0 0 31px">
      <CustomText fontSize={2}>{cnt}</CustomText>
      <CustomText fontSize={2}>/</CustomText>
      <CustomText fontSize={2} bold={700}>
        {percentage}%
      </CustomText>
    </Stack>
  </PieInfoContainer>
);
