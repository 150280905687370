import React, { useEffect } from 'react';
import { useForm } from '../../hooks/useForm';

import { YearInput, MonthInput } from '../../common/Input/DateInput';
import { CustomButton, Flex } from '../../common';
import moment from 'moment';
import { FilterModal, FilterPropTypes } from '../../pages/Transactions/FilterModal';

interface BigFilterPropTypes extends FilterPropTypes {
  onSave: (value: any) => void;
}

export const FilterYearMonth: React.FC<BigFilterPropTypes> = ({ open, handleClose, onSave }) => {
  const { values, forceChange } = useForm<{
    year: number;
    month: number;
  }>({
    year: null,
    month: null,
  });
  return (
    <FilterModal open={open} handleClose={handleClose}>
      <Flex column>
        <Flex>
          <YearInput
            value={values.year}
            onChange={(e: any) => {
              forceChange(moment(e).format('YYYY'), 'year');
            }}
            name="year"
            id="year"
            info="Year"
            placeholder="Month"
          />
          <MonthInput
            value={values.month}
            onChange={(e: any) => {
              forceChange(moment(e).format('M'), 'month');
            }}
            name="month"
            id="month"
            info="Month"
          />
        </Flex>
        <Flex>
          <CustomButton
            content="confirm"
            width="100%"
            height="56px"
            onClick={() => onSave(values)}
          />
        </Flex>
      </Flex>
    </FilterModal>
  );
};
