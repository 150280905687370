import React from 'react';

export const LoginLogo = () => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M113.152 102.323L109.159 94.7456C110.746 93.3888 111.77 91.3664 111.77 89.1136C111.77 85.8112 109.594 83.0208 106.624 82.048V43.4432L110.618 41.7536L63.5908 20.8384L15.002 40.96L63.5652 61.2864L102.247 44.7744V81.9712C99.1748 82.8672 96.922 85.7344 96.922 89.1136C96.922 91.3664 97.946 93.3888 99.5331 94.7456L95.5396 102.323L100.48 102.349L103.552 96.4864C103.808 96.512 104.09 96.5376 104.346 96.5376C104.602 96.5376 104.884 96.512 105.14 96.4864L108.212 102.349L113.152 102.323ZM63.5396 55.4496L28.0324 40.8832L63.5652 26.7008L97.8436 41.088L63.5396 55.4496ZM104.346 86.0416C106.036 86.0416 107.392 87.3984 107.392 89.088C107.392 90.7776 106.036 92.1344 104.346 92.1344C102.656 92.1344 101.3 90.7776 101.3 89.088C101.3 87.3984 102.656 86.0416 104.346 86.0416Z"
        fill="white"
      />
      <path
        d="M99.5071 51.9936L63.5903 64.2048L27.6735 51.9936L27.2383 51.7888V78.2848L61.8495 95.7184L63.5647 96.5632L65.8687 95.4112L99.8911 78.2848V67.84V62.0544V51.7888L99.5071 51.9936ZM61.2863 90.24L31.8719 75.392V59.2128L61.2863 73.7536V90.24ZM63.5903 69.7344L42.3935 59.264L63.5903 66.4576L84.7871 59.264L63.5903 69.7344ZM95.3087 69.8368V75.392L65.8943 90.24V73.7536L95.3087 59.2128V64.256L90.7007 66.4576V71.8336L95.3087 69.8368Z"
        fill="white"
      />
    </svg>
  );
};
