import { Avatar, styled } from '@mui/material';
import React from 'react';

export const StyledAvatar = styled(Avatar)<{
  height?: number;
  width?: number;
  blueOutline?: boolean;
}>`
  height: ${({ height }) => (height ? `${height}px` : '37px')};
  width: ${({ width }) => (width ? `${width}px` : '37px')};
  border: ${({ blueOutline }) => (blueOutline ? '1px solid #0C84FC' : 'none')};
`;

interface SmallCustomAvatarProps {
  image?: string;
  desc?: string;
}

export const SmallCustomAvatar: React.FC<SmallCustomAvatarProps> = ({ image, desc }) => (
  <StyledAvatar src={image} alt={desc} />
);
