import React from 'react';

export const Logout = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3538_3000)">
        <path
          d="M19.1184 10.4429C19.1225 10.4388 19.1225 10.4388 19.1265 10.4347C19.1388 10.4184 19.151 10.4062 19.1592 10.3898C19.1633 10.3857 19.1633 10.3817 19.1674 10.3776C19.1755 10.3613 19.1878 10.3449 19.1959 10.3286C19.1959 10.3245 19.2 10.3204 19.2 10.3204C19.2082 10.3041 19.2163 10.2878 19.2245 10.2674C19.2245 10.2633 19.2245 10.2633 19.2286 10.2592C19.2368 10.2429 19.2408 10.2224 19.249 10.202C19.249 10.198 19.249 10.1939 19.2531 10.1939C19.2572 10.1735 19.2653 10.1571 19.2653 10.1367C19.2653 10.1286 19.2653 10.1245 19.2694 10.1163C19.2735 10.1 19.2735 10.0836 19.2776 10.0673C19.2817 10.0428 19.2817 10.0224 19.2817 9.99791C19.2817 9.97342 19.2817 9.953 19.2776 9.92851C19.2776 9.91218 19.2735 9.89585 19.2694 9.87952C19.2694 9.87136 19.2694 9.86727 19.2653 9.85911C19.2613 9.8387 19.2572 9.82237 19.2531 9.80195C19.2531 9.79787 19.2531 9.79379 19.249 9.79379C19.2449 9.77338 19.2368 9.75705 19.2286 9.73663C19.2286 9.73255 19.2286 9.73255 19.2245 9.72847C19.2163 9.71214 19.2082 9.69173 19.2 9.6754C19.2 9.67132 19.1959 9.66723 19.1959 9.66723C19.1878 9.6509 19.1796 9.63457 19.1674 9.61824C19.1633 9.61416 19.1633 9.61008 19.1592 9.606C19.1469 9.58967 19.1388 9.57334 19.1265 9.56109C19.1225 9.55701 19.1225 9.55701 19.1184 9.55292C19.102 9.53659 19.0898 9.51618 19.0694 9.49985L15.0318 5.46637C14.7583 5.19285 14.3133 5.19285 14.0398 5.46637C13.7663 5.7399 13.7663 6.18489 14.0398 6.45841L16.8812 9.29981H5.585C5.19716 9.29981 4.88281 9.61416 4.88281 9.99791C4.88281 10.3857 5.19716 10.7001 5.585 10.7001H16.8853L14.0643 13.5211C13.7908 13.7946 13.7908 14.2396 14.0643 14.5131C14.199 14.6478 14.3786 14.7172 14.5583 14.7172C14.7379 14.7172 14.9175 14.6478 15.0522 14.5131L19.0653 10.5001C19.0857 10.4756 19.102 10.4592 19.1184 10.4429Z"
          fill="#0C84FC"
        />
        <path
          d="M4.51953 1.40029H9.74508C10.1329 1.40029 10.4473 1.08594 10.4473 0.702184C10.4473 0.31435 10.1329 0 9.74508 0H4.51953C2.42522 0 0.71875 1.70647 0.71875 3.80078V16.1992C0.71875 18.2935 2.42522 20 4.51953 20H9.65935C10.0472 20 10.3615 19.6856 10.3615 19.3019C10.3615 18.9141 10.0472 18.5997 9.65935 18.5997H4.51953C3.19681 18.5997 2.11904 17.5219 2.11904 16.1992V3.80078C2.12312 2.47397 3.19681 1.40029 4.51953 1.40029Z"
          fill="#0C84FC"
        />
      </g>
      <defs>
        <clipPath id="clip0_3538_3000">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
