import { Box, Stack, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

import { SectionHeaderInfo } from '../../common';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { DATE_FULL_MONTH_ORDER, DATE_TIME, ROUTES } from '../../constants';
import { resetOrderDetail, setOrderDetail } from '../../store/slice';
import { useAppSelector } from '../../store';
import { OrderDetailHeader } from './OrderDetailHeader';
import { CustomText } from '../../styles';
import { OrderDetailItem } from './OrderDetailItem';

export const OrderDetail = () => {
  const { orderDetail } = useAppSelector((state) => state.store);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  if (!orderDetail) return <Navigate to={ROUTES.ORDERS} />;

  return (
    <>
      <RouteInfo
        smImage="/orders-sm.png"
        banner="Orders"
        description="Create orders for supliers"
        margin="1% 0"
      />
      <SectionHeaderInfo
        banner={
          <Box>
            {orderDetail.student.name}
            <CustomText fontSize={0.75}>
              {format(orderDetail.date, `${DATE_FULL_MONTH_ORDER} - ${DATE_TIME}`)}
            </CustomText>
          </Box>
        }
        onClick={() => {
          dispatch(resetOrderDetail('ok'));
          navigate(ROUTES.ORDERS);
        }}
      />

      <Stack
        height="67vh"
        width="100%"
        padding="20px"
        spacing={2}
        sx={{ overflowY: 'auto', overflowX: 'hidden' }}
      >
        <OrderDetailHeader />
        {orderDetail &&
          orderDetail?.products?.map((product: any) => (
            <OrderDetailItem key={product.id} {...product} />
          ))}
        <Stack width="100%" direction="row" justifyContent="flex-end" spacing={1}>
          <CustomText fontSize={1.6}>Total Amount: </CustomText>
          <CustomText fontSize={1.6} bold={700} color={theme.palette.success.light}>
            {`£ ${orderDetail.totalPrice.toFixed(2)}`}
          </CustomText>
        </Stack>
      </Stack>
    </>
  );
};
