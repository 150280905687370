import React from "react";
import { Link } from "react-router-dom";

import { CollegeHat } from "../../svg/CollegeHat";
import { LogoContainer, LogoText } from "./Nav.style";

export const Logo = () => (
  <Link to="/">
    <LogoContainer>
      <CollegeHat />
      <LogoText>Pupil Wallet</LogoText>
    </LogoContainer>
  </Link>
)
