import React from 'react';

import { ChartInfoContainer, DescContainer, StyledDot } from './ChartInfo.style';
import CountUp from 'react-countup';
import { CustomText } from '../../styles';

interface ChartInfoProps {
  banner: string;
  bgcolor: string;
  amount: number;
  percentage: number;
  noBorder?: boolean;
  height?: string;
  width?: string;
  margin?: string;
  last?: boolean;
}

export const ChartInfo: React.FC<ChartInfoProps> = ({
  banner,
  amount,
  percentage,
  bgcolor,
  height,
  width,
  last,
}) => (
  <ChartInfoContainer last={last}>
    <DescContainer gap={20}>
      <StyledDot height={height} width={width} bgcolor={bgcolor} />
      <CustomText fontSize={1.1} bold={700}>
        {banner}
      </CustomText>
    </DescContainer>
    <DescContainer>
      <CustomText fontSize={1.2} bold={300}>
        {amount ? <CountUp end={amount} duration={2} /> : '0'}
      </CustomText>
      <CustomText fontSize={1.2} bold={300}>
        /
      </CustomText>
      <CustomText fontSize={1.2} bold={700}>
        {percentage ? <CountUp end={percentage} duration={2} /> : '0'}%
      </CustomText>
    </DescContainer>
  </ChartInfoContainer>
);
