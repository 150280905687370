import { Box, Stack, styled } from '@mui/material';

export const OverviewContainer = styled(Stack)`
  flex: 1;
  width: 100%;
  padding: 6px 20px;
  overflow-y: auto;
  position: relative;

  @media (max-width: 1024px) {
    overflow-y: scroll;
  }
`;

export const SectionContainer = styled(Stack)`
  @media (max-width: 1024px) {
    height: auto;
  }
`;

export const OverviewItemContainer = styled(Box)`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
`;

export const EarningsContainer = styled(OverviewItemContainer)`
  height: 100%;
  flex: 0 0 33%;
`;

export const EarningsContent = styled(Box)<{ gap?: string }>`
  height: 77%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ gap }) => gap || '0'};
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: 16px;
  }
`;

export const EarningsAnalyticContainer = styled(OverviewItemContainer)`
  height: 100%;
  flex: 0 0 66%;
`;

export const GraphContainer = styled(Box)`
  height: 77%;
  width: 100%;
  padding: 18px 25px 18px 0;
`;

export const TopSellingProductsContainer = styled(OverviewItemContainer)`
  height: 100%;
  flex: 0 0 59%;

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 9%;
  }
`;

export const TopSellingProductsContent = styled(Stack)`
  height: 77%;
  width: 100%;
  padding: 18px;

  @media (max-width: 1024px) {
    height: 90%;
    width: 100%;
  }
`;

export const OverviewBannerItem = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 18px 25px;
  position: relative;

  &::after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.color.divider};
    position: absolute;
    left: 0;
    bottom: 0;
  }

  @media (max-width: 1024px) {
    height: auto;
  }
`;

export const AnalyticPieItemContainer = styled(Box)`
  height: 100%;
  width: 23%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.color.divider};
  border-radius: 10px;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 2% 0;
  }
`;

export const ProductStatusWidgetContainer = styled(OverviewItemContainer)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 29px 18px;
  padding-right: 0;
  position: relative;
`;

export const ProductStatusWidgetArrow = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 14%;
  cursor: pointer;
  border-left: 1px solid ${({ theme }) => theme.color.divider};
`;

export const NotificationsContainer = styled(OverviewItemContainer)`
  flex: 0 0 20%;
  overflow-y: auto;
  position: relative;

  @media (max-width: 1024px) {
    min-height: 90vh;
  }
`;

export const ProductCard = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.color.borderGrey};
  padding: 20px;
`;
