import React from 'react';

export const MailDownload = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 10.1893V1.5C7.25 1.08579 7.58579 0.75 8 0.75C8.41421 0.75 8.75 1.08579 8.75 1.5V10.1893L10.4697 8.46967C10.7626 8.17678 11.2374 8.17678 11.5303 8.46967C11.8232 8.76256 11.8232 9.23744 11.5303 9.53033L8.53093 12.5297L8.53033 12.5303M7.25 10.1893L5.53033 8.46967C5.23744 8.17678 4.76256 8.17678 4.46967 8.46967C4.17678 8.76256 4.17678 9.23744 4.46967 9.53033L7.46907 12.5297L7.46967 12.5303M8.28709 12.6931C8.19866 12.7298 8.10169 12.75 8 12.75C7.79538 12.75 7.60991 12.6681 7.47459 12.5352M8.52541 12.5352C8.45465 12.6047 8.37354 12.6573 8.28709 12.6931ZM0.5 12.75V15C0.5 16.2426 1.50736 17.25 2.75 17.25H13.25C14.4926 17.25 15.5 16.2426 15.5 15V12.75C15.5 12.3358 15.1642 12 14.75 12C14.3358 12 14 12.3358 14 12.75V15C14 15.4142 13.6642 15.75 13.25 15.75H2.75C2.33579 15.75 2 15.4142 2 15V12.75C2 12.3358 1.66421 12 1.25 12C0.835786 12 0.5 12.3358 0.5 12.75Z"
        fill="#0C84FC"
      />
    </svg>
  );
};
