import { CircularProgress, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { getAnalytics } from '../../../../api';
import { Flex } from '../../../../common';
import { ChartContainer, ChartContainerContent } from '../../../../components/Charts/Chart.style';
import { ChartInfo } from '../../../../components/Charts/ChartInfo';
import { ChartTitle } from '../../../../components/Charts/ChartTitle';
import { UserInfoPieChart } from '../../../../components/Charts/UserInfoPieChart';
import { FilterYearMonthUser } from '../../../../components/FilterModals/FilterYearMonthUser';
import { CustomText } from '../../../../styles';
import { Right } from './TransactionsAnalytics.style';

const initialFilter = {
  year: null,
  month: null,
  user: {
    name: null,
    uid: null,
  },
};

export const TotalTransactionsPieChart = () => {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(initialFilter);
  const [value, setValue] = useState({
    creditsCount: 0,
    schoolBillCount: 0,
    user: {
      imageUrl: null,
      name: null,
    },
  });
  const [totalValue, setTotalValue] = useState(0);

  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };
  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('transactions/total-student', {
      year: null,
      month: null,
      userId: filter.user.uid,
    });
    setValue(data?.data.data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);

  const data = [
    { name: 'Credits Transactions', value: value.creditsCount, color: theme.color.depositGreen },
    { name: 'School Bill', value: value.schoolBillCount, color: theme.color.graphRed },
  ];

  const calculatePres = (number: number) => {
    return Math.floor((number * 100) / 300);
  };

  const clearFilter = () => setFilter(initialFilter);
  return (
    <ChartContainer>
      <ChartTitle
        title={`Total transactions:${value.creditsCount}`}
        onClick={() => setFilterOpen(true)}
        filter={{
          year: filter.year,
          month: filter.month,
          user: filter.user.name,
        }}
        initialFilter={initialFilter}
        realFilter={filter}
        clearFilter={clearFilter}
      />
      <ChartContainerContent>
        {loading ? (
          <Flex fullHeight contectCenter>
            <CircularProgress size="4rem" />
            <CustomText fontSize={1.4} color={theme.background.blue}>
              Loading...
            </CustomText>
          </Flex>
        ) : (
          <ResponsiveContainer height="100%" width="100%">
            <Flex fullHeight>
              <Flex p={20} fullHeight>
                <ResponsiveContainer width="40%" height="100%">
                  <PieChart>
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius="100%"
                      dataKey="value"
                    >
                      {data.map((item: any, index: number) => (
                        <Cell key={index} fill={item.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <Right>
                  <UserInfoPieChart image={value.user?.imageUrl} name={value.user?.name} />
                  {data.map((item: any, index: number) => (
                    <ChartInfo
                      key={index}
                      bgcolor={item.color}
                      banner={item.name}
                      amount={item.value}
                      percentage={calculatePres(item.amount)}
                      last={index + 1 === data.length}
                    />
                  ))}
                </Right>
              </Flex>
            </Flex>
          </ResponsiveContainer>
        )}
      </ChartContainerContent>
      <FilterYearMonthUser
        open={filterOpen}
        handleClose={() => setFilterOpen(false)}
        onSave={onSave}
      />
    </ChartContainer>
  );
};
