import { InputLabel, styled } from '@mui/material';
import React, { useRef, useState } from 'react';
import { getBase64 } from '../../helpers';
import { InputFieldProps } from './Input';
import { InputWrapper } from './InputWrapper';

const AttachStyled = styled(InputLabel)<{ bgcolor?: string }>`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, bgcolor }) => bgcolor || theme.color.lightGrey};
  padding: 4px 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.common.black};
  border-radius: 10px;
  margin: 4% 0;
`;

interface AttachFileProps extends InputFieldProps {
  bgcolor?: string;
}

export const AttachFile: React.FC<AttachFileProps> = ({
  children,
  info,
  id,
  width,
  alignitems,
  padding,
  bgcolor,
  margin,
  onChange,
  ...rest
}) => {
  const [fileUrl, setFileUrl] = useState('');
  const ref: any = useRef();
  const handleChange = async (e: any) => {
    const url: any = await getBase64(e.target.files[0]);
    const formattedBinary = url.split(',')[1];
    const type = url.split(';')[0].split(':')[1].split('/')[1];
    setFileUrl(url);
    onChange?.({
      type,
      data: formattedBinary,
    });
  };
  return (
    <InputWrapper width={width} alignitems={alignitems} info={info}>
      <AttachStyled htmlFor={id} bgcolor={bgcolor}>
        {ref?.current?.files[0]?.name || children}
      </AttachStyled>
      <input
        type="file"
        id={id}
        accept=".doc,.docx,.pdf,.jpg,.jpeg,.png"
        {...rest}
        style={{ display: 'none' }}
        onChange={handleChange}
        ref={ref}
      />
    </InputWrapper>
  );
};
