import { useTheme } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { thousandToK } from '../../helpers';

import { CustomText } from '../../styles';
import { EarningsAnalyticContainer, GraphContainer, OverviewBannerItem } from './Overview.style';

export const EarningAnalytics: React.FC<{ value: { hourStartDate: number; total: number }[] }> = ({
  value,
}) => {
  const theme = useTheme();
  return (
    <EarningsAnalyticContainer>
      <OverviewBannerItem>
        <CustomText fontSize={1.2} bold={700}>
          Earnings Analytics
        </CustomText>
        <CustomText>Today</CustomText>
      </OverviewBannerItem>

      <GraphContainer>
        <ResponsiveContainer height="100%" width="100%">
          <LineChart height={400} width={650} data={value}>
            <CartesianGrid vertical={false} />
            <YAxis
              axisLine={false}
              tickLine={false}
              dataKey="total"
              tickFormatter={(val: number) => `$${thousandToK(val / 100)}`}
            />
            <XAxis
              dataKey="hourStartDate"
              tickLine={false}
              tickFormatter={(val: number) => `${moment.unix(val).format('hh:mm')}`}
            />
            <Line
              type="linear"
              dataKey="total"
              stroke={theme.background.blue}
              dot={{ r: 6, fill: theme.background.blue }}
              activeDot={false}
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </GraphContainer>
    </EarningsAnalyticContainer>
  );
};
