import { useTheme } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Circle } from '../../common';

import { CustomText } from '../../styles';
import { EarningsContainer, EarningsContent, OverviewBannerItem } from './Overview.style';

export const EarningsItem: React.FC<{ value: number }> = ({ value }) => {
  const theme = useTheme();
  return (
    <EarningsContainer>
      <OverviewBannerItem>
        <CustomText fontSize={1.2} bold={700}>
          Earnings
        </CustomText>
        <CustomText>Today</CustomText>
      </OverviewBannerItem>
      <EarningsContent gap="11px">
        <Circle height="83px" width="83px">
          <img src="/wallet-sm.png" alt="" />
        </Circle>
        <CustomText fontSize={2} bold={700} color={theme.background.blue}>
          {value && `£${value / 100}`}
        </CustomText>
        <CustomText bold={300}>Today / {moment().format('Do MMMM, YYYY')}</CustomText>
      </EarningsContent>
    </EarningsContainer>
  );
};
