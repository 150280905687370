import React from 'react';

import { AddCircle, AddCircleContainer } from '../Orders.style';

interface AddOrderCircle {
  onClick: () => void;
}

export const AddOrderCircle: React.FC<AddOrderCircle> = ({ onClick }) => (
  <AddCircleContainer>
    <AddCircle onClick={onClick}>
      <img src="/add.png" alt="" />
    </AddCircle>
  </AddCircleContainer>
)
