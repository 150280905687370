import { Stack, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Flex, WhiteContainer } from '../../common';

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { useOverview } from '../../hooks/useOverview';
import { EarningAnalytics } from './EarningAnalytics';
import { EarningsItem } from './EarningsItem';
import { OverviewContainer } from './Overview.style';
import { TopProducts } from './TopProducts';

export const Overview = () => {
  const theme = useTheme();
  const { overview } = useOverview();

  return (
    <>
      <RouteInfo />
      <OverviewContainer>
        <Flex column fullHeight>
          <Flex>
            <EarningsItem value={overview?.todayEarnings} />
            <EarningAnalytics value={overview?.todayTransactions} />
          </Flex>
          <Flex>
            <TopProducts
              boughtProducts={overview?.boughtProducts}
              lowProductsCount={overview?.lowProductsCount}
              todayTransactionCount={overview?.todayTransactionCount}
            />
          </Flex>
        </Flex>
      </OverviewContainer>
    </>
  );
};
