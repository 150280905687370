import { Star, StarBorder } from '@mui/icons-material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MailListItem, MailListItemContent, Marker, MuiIcon } from './Mail.style';
import moment from 'moment';

import { Skeleton } from '@mui/material';
import { favoriteMail } from '../../api';
import { Flex } from '../../common';
import { CustomText } from '../../styles';
import { ROUTES } from '../../constants';
import { setMailItem } from '../../store/slice';
import { useNotification } from '../../hooks/useNotification';

interface MailItemProps {
  type: string;
  user: any;
  subject: string;
  date: number;
  unread: boolean;
  isFavorite: boolean;
  sentType: string;
  id: string;
  body: string;
  fileUrl?: string | null;
  offerResponse?: number;
  paymentOffer?: number;
}

export const MailItem: React.FC<MailItemProps> = ({
  user,
  subject,
  date,
  unread,
  isFavorite,
  sentType,
  fileUrl,
  id,
  body,
  offerResponse,
  paymentOffer,
  type,
}) => {
  const [fav, setFav] = useState(!!isFavorite);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bold = unread ? 700 : 300;
  const { notify, notifyError } = useNotification();

  const setMailFavorite = async (e: any) => {
    e.stopPropagation();
    setFav((prevState) => !prevState);
    const res = await favoriteMail(id, !fav);
    if (!res.data.error) {
      notify({ message: res.data.message });
    } else {
      notifyError(res.data.message);
      setFav((prevState) => !prevState);
    }
  };

  return (
    <MailListItem
      cursor="pointer"
      onClick={() => {
        dispatch(
          setMailItem({
            user,
            subject,
            date,
            isFavorite,
            sentType,
            id,
            body,
            fileUrl,
            offerResponse,
            paymentOffer,
            type,
          }),
        );
        navigate(`${ROUTES.MAIL_PAGE}/${id}`);
      }}
    >
      {unread && <Marker bc />}
      <MailListItemContent flex={15}>
        <Flex>
          <MuiIcon onClick={setMailFavorite}>{fav ? <Star /> : <StarBorder />}</MuiIcon>
          <CustomText bold={bold}>{user?.name}</CustomText>
        </Flex>
      </MailListItemContent>
      <MailListItemContent flex={20}>
        <CustomText bold={bold}>{subject}</CustomText>
      </MailListItemContent>
      <MailListItemContent flex={10}>
        <CustomText bold={bold}>{moment.unix(date).format('MM/DD/YYYY')}</CustomText>
      </MailListItemContent>
      <MailListItemContent flex={10}>
        <CustomText bold={bold}>{moment.unix(date).format('HH:MM')}</CustomText>
      </MailListItemContent>
    </MailListItem>
  );
};

export const MailItemSkeleton = () => {
  return (
    <MailListItem cursor="pointer">
      <MailListItemContent flex={15}>
        <Flex>
          <Skeleton height={24} width={24} variant="circular" />
          <Skeleton height="1.2rem" width={300} />
        </Flex>
      </MailListItemContent>
      <MailListItemContent flex={20}>
        <Skeleton height="1.2rem" width={200} />
      </MailListItemContent>
      <MailListItemContent flex={10}>
        <Skeleton height="1.2rem" width={70} />
      </MailListItemContent>
      <MailListItemContent flex={10}>
        <Skeleton height="1.2rem" width={50} />
      </MailListItemContent>
    </MailListItem>
  );
};
