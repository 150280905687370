import CloseIcon from '@mui/icons-material/Close';
import { IconButton, useTheme } from '@mui/material';
import React from 'react';
import { CustomText } from '../../styles';
import { ModalBanner, ModalContainer, ModalContent } from './CustomModal.style';

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  content: () => React.ReactElement;
  banner?: string;
  width?: string;
}

export const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  content,
  banner = 'Add Product',
  width,
}) => {
  const theme = useTheme();

  return (
    <ModalContainer open={isOpen} onClose={onClose}>
      <ModalContent width={width}>
        <ModalBanner>
          <CustomText fontSize={1.6} bold={700} color={theme.background.blue}>
            {banner}
          </CustomText>

          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </ModalBanner>
        {content()}
      </ModalContent>
    </ModalContainer>
  );
};
