import { useTheme } from '@mui/material';
import React from 'react';
import { Flex } from '../../../../common';
import { StyledDot } from '../../../../components/Charts/ChartInfo.style';
import { CustomText } from '../../../../styles';
import { PopularProductItemInfo, PopularProductItemContainer } from './AnalitycsProducts.style';

interface PopularProductItemProps {
  color: string;
  name: string;
  category: string;
  amount: number;
  count: number;
  last: boolean;
}

export const PopularProductItem: React.FC<PopularProductItemProps> = ({
  color,
  name,
  category,
  amount,
  count,
  last,
}) => {
  const theme = useTheme();
  return (
    <PopularProductItemContainer last={last}>
      <PopularProductItemInfo>
        <Flex fullHeight>
          <StyledDot bgcolor={color} />
        </Flex>
        <Flex>
          <CustomText bold={700}>{name}</CustomText>
        </Flex>
        <Flex></Flex>
        <CustomText>{category}</CustomText>
      </PopularProductItemInfo>
      <Flex flexEnd column alignEnd gap="0px">
        <CustomText color={theme.background.blue} bold={700} fontSize={1.7}>
          £{amount / 100}
        </CustomText>
        <CustomText>Orders: {count}</CustomText>
      </Flex>
    </PopularProductItemContainer>
  );
};
