import React, { useEffect } from 'react';
import { useForm } from '../../hooks/useForm';

import { YearInput, MonthInput } from '../../common/Input/DateInput';
import { CustomButton, Flex, SelectField } from '../../common';
import moment from 'moment';
import { FilterModal, FilterPropTypes } from '../../pages/Transactions/FilterModal';
import { useCategories } from '../../hooks/useCategories';
import { MenuItem } from '@mui/material';
import { useProducts } from '../../hooks/useProducts';

interface BigFilterPropTypes extends FilterPropTypes {
  onSave: (value: any) => void;
}

export const FilterYearMonthCategoryProduct: React.FC<BigFilterPropTypes> = ({
  open,
  handleClose,
  onSave,
}) => {
  const { values, forceChange, onChange } = useForm<{
    year: string;
    month: number;
    category: {
      name: string;
      id: string;
    };
    product: {
      name: string;
      id: string;
    };
  }>({
    year: null,
    month: null,
    category: null,
    product: null,
  });
  const { categories, isLoading: categoriesIsloading } = useCategories();
  const { products } = useProducts();
  return (
    <FilterModal open={open} handleClose={handleClose}>
      <Flex column>
        <Flex>
          <YearInput
            value={values.year}
            onChange={(e: any) => {
              forceChange(moment(e).format('YYYY'), 'year');
            }}
            name="year"
            id="year"
            info="Year"
          />
          <MonthInput
            value={values.month}
            onChange={(e: any) => {
              forceChange(moment(e).format('M'), 'month');
            }}
            name="month"
            id="month"
            info="Month"
          />
        </Flex>
        <SelectField
          id="categoryName"
          info="Category Name"
          name="category"
          value={values.category}
          onChange={onChange}
        >
          {categories.map((category: any) => (
            <MenuItem key={category.id} value={category}>
              {category.name}
            </MenuItem>
          ))}
        </SelectField>
        <SelectField
          id="productName"
          info="Product Name"
          name="product"
          value={values.product}
          onChange={onChange}
        >
          {products.map((items: any, index: number) =>
            Object.values(items).map((item: any, index: number) => (
              <MenuItem key={item.id} value={item}>
                {item.name}
              </MenuItem>
            )),
          )}
        </SelectField>
        <Flex>
          <CustomButton
            content="confirm"
            width="100%"
            height="56px"
            onClick={() => onSave(values)}
          />
        </Flex>
      </Flex>
    </FilterModal>
  );
};
