import { useState, useEffect } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';

import { useNotification } from './useNotification';
import { getOrders, addOrder, createOrderOnHold } from '../api';

export const useOrders = () => {
  const [orders, setOrders] = useState<any[]>([]);
  const [date, setDate] = useState<number | undefined>(undefined);
  const { notify, notifyError } = useNotification();
  const queryClient = useQueryClient();
  const { data, isLoading, isFetchingNextPage, fetchNextPage } = useInfiniteQuery(
    ['orders', { date }],
    getOrders,
    {
      getNextPageParam: (lastPage, pages) => pages.length + 1,
      refetchOnMount: true,
    },
  );

  const mutation = useMutation('orders', addOrder, {
    onSuccess: () => {
      notify({ message: 'Order added succesfully' });
      queryClient.invalidateQueries('orders');
    },
    onError: (e: any) => {
      notifyError(e.message);
    },
  });

  const mutationOnHold = useMutation('orders', createOrderOnHold, {
    onSuccess: () => {
      notify({ message: 'Order on hold created succesfully' });
      queryClient.invalidateQueries('orders');
    },
    onError: (e: any) => {
      notifyError(e.message);
    },
  });

  useEffect(() => {
    if (data) {
      const arr = data.pages.map((item: any) => item.data.data.orders);
      setOrders(arr);
    }
  }, [data]);

  return {
    data,
    mutation,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    orders,
    mutationOnHold,
    date,
    setDate,
  };
};
