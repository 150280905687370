import { Star, StarBorder } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { CustomButton, DownloadLink, Flex } from '../../../common';
import { StyledAvatar } from '../../../common/CustomAvatar';
import { CustomText } from '../../../styles';
import { MailDownload } from '../../../svg/MailDownload';

import {
  MessagesContainer,
  PersonInfoContainer,
  PersonInfoContent,
  DateText,
} from '../../InternalSupportTicket/InternalSupportTicket.style';
import { MuiIcon } from '../Mail.style';

export interface MailMessageProps {
  user: any;
  email: string;
  subject: string;
  date: number;
  isFavorite: boolean;
  sentType: string;
  body?: string;
  fileUrl?: string;
  type: string;
  paymentOffer?: number;
  offerResponse?: string;
}

export const MailMessage: React.FC<MailMessageProps> = ({
  user,
  email,
  subject,
  date,
  sentType,
  isFavorite,
  fileUrl,
  body,
  type,
  paymentOffer,
  offerResponse,
}) => {
  const theme = useTheme();
  return (
    <MessagesContainer bottom="ok">
      <PersonInfoContainer>
        <PersonInfoContent gap={19}>
          <MuiIcon>{isFavorite ? <Star /> : <StarBorder />}</MuiIcon>
          <StyledAvatar height={82} width={82} />
          <Box>
            <CustomText fontSize={1.2} bold={700}>
              {sentType === 'inbox' ? 'FROM' : 'TO'}: {user.name}
            </CustomText>
            <CustomText>{email}</CustomText>
          </Box>
        </PersonInfoContent>
        <Flex column alignEnd>
          {fileUrl && (
            <DownloadLink link={fileUrl}>
              <Flex flexEnd gap="5px" pointer noFull>
                <MailDownload />
                <CustomText color={theme.background.blue}>Download Attachment</CustomText>
              </Flex>
            </DownloadLink>
          )}
          <DateText>{moment.unix(date).format('DD.MM.YYYY, HH:MM')}</DateText>
        </Flex>
      </PersonInfoContainer>
      <Flex p="0 20" column noCenter>
        <CustomText bold={700} margin="2% 0">
          Subject: {subject}
        </CustomText>

        <CustomText bold={300}>{body}</CustomText>
      </Flex>
      {type === 'payRequest' && (
        <Flex flexEnd p="2rem 0" spaceBetween>
          <Flex>
            {paymentOffer && (
              <CustomText fontSize={2} bold={700} color={theme.color.depositGreen}>
                Price: £{paymentOffer / 100}
              </CustomText>
            )}
          </Flex>
          <CustomButton
            width="15rem"
            bgcolor={
              offerResponse === 'accepted'
                ? theme.color.depositGreen
                : offerResponse === 'denied'
                ? theme.color.graphRed
                : theme.background.blue
            }
            content={offerResponse ? offerResponse : 'pending'}
          />
        </Flex>
      )}
    </MessagesContainer>
  );
};
