import React from 'react';
import { ROUTES } from '../../constants';
import { Logout } from '../../svg/Logout';
import { SettignsMail } from '../../svg/SettignsMail';
import { SettingsInfo } from '../../svg/SettingsInfo';

interface SettingsLink {
  name: string;
  icon: any;
  link: string;
}

export const settingsLinks: SettingsLink[] = [
  { name: 'Internal Support', icon: <SettignsMail />, link: ROUTES.INTERNAL_SUPPORT },
  { name: 'Terms and Conditions', icon: <SettingsInfo />, link: ROUTES.TERMS },
  { name: 'Privacy Policy', icon: <SettingsInfo />, link: ROUTES.POLICY },
];
