import { Box, styled } from '@mui/material';
import { Marker } from '../Mail/Mail.style';

export const InternalSupportContainer = styled(Box)`
  height: 80vh;
  width: 100%;
  padding: 16px;
  position: relative;
`;

export const SupportMarker = styled(Marker)<{ active?: boolean }>`
  background-color: ${({ theme, active }) =>
    active ? theme.palette.success.light : theme.palette.error.light};
`;

export const InternalSupportHeadersContainer = styled(Box)`
  height: 44px;
  width: 100%;
  display: flex;
  padding: 20px 20px;
  position: relative;
  align-items: center;
`;
