import React, { useEffect, useState } from 'react';
import { useUsers } from '../../hooks/useUsers';
import { AutocompleteInput } from './AutocompleteInput';

export const UserInput: React.FC<{ value: any; onChange: any; info?: string }> = ({
  value,
  onChange,
  info,
}) => {
  const { users } = useUsers();
  const [userOptions, setUserOptions] = useState<any[]>([]);

  useEffect(() => {
    const arr: any[] = [];
    users.map((users: any) =>
      Object.values(users).map((user: any, index: number) => arr.push(user)),
    );
    setUserOptions(arr);
  }, [users]);
  return (
    <AutocompleteInput
      value={value}
      name="studentId"
      info={info || 'Student Name'}
      padding="4px 20px"
      placeholder="Select"
      options={userOptions}
      onChange={onChange}
    />
  );
};
