import React, { useEffect, useRef, useState } from 'react';
import { getAnalytics } from '../../../../api';
import { Flex } from '../../../../common';
import { ChartContainer, ChartContainerContent } from '../../../../components/Charts/Chart.style';
import { ChartTitle } from '../../../../components/Charts/ChartTitle';
import { FilterYearMonthCategory } from '../../../../components/FilterModals/FilterYearMonthCategory';
import { PopularProductItemListContainer } from './AnalitycsProducts.style';
import { PopularProductItem } from './PopularProductItem';

const initialFilter = {
  year: null,
  month: null,
  category: {
    name: null,
    id: null,
  },
};

export const PopularProductsList = () => {
  const [filter, setFilter] = useState(initialFilter);

  const [filterOpen, setFilterOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState([]);

  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };
  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('products/popular-products', {
      year: filter.year,
      month: filter.month,
      categoryId: filter.category.id,
    });
    setValue(data?.data.data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);
  const ref: any = useRef();
  const clearFilter = () => setFilter(initialFilter);
  return (
    <ChartContainer>
      <ChartTitle
        title="Popular Products"
        onClick={() => setFilterOpen(true)}
        initialFilter={initialFilter}
        realFilter={filter}
        clearFilter={clearFilter}
        filter={{
          year: filter.year,
          month: filter.month,
          category: filter.category.name,
        }}
      />
      <Flex ref={ref} fullHeight p="0 20">
        {!loading && (
          <PopularProductItemListContainer height={ref?.current?.clientHeight}>
            {value.map((item: any, index) => (
              <PopularProductItem
                key={item.product.id}
                name={item.product.name}
                category={item.product.categoryName}
                color={item.product.categoryHexColor}
                amount={item.product.price}
                count={item.totalOrders}
                last={index + 1 === 10}
              />
            ))}
          </PopularProductItemListContainer>
        )}
      </Flex>
      <FilterYearMonthCategory
        open={filterOpen}
        handleClose={() => setFilterOpen(false)}
        onSave={onSave}
      />
    </ChartContainer>
  );
};
