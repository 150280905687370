import React, { createContext, ReactNode, useEffect, useState } from 'react';

import { signInWithCustomToken, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../config/db';
import { ACCESS_TOKEN, BEARER, USER } from '../config/axiosConf';
import { ROUTES } from '../constants';

import { login } from '../api';
import { useNavigate } from 'react-router-dom';
import { dbGetUserByUidOnce } from '../reader/dbGetUserByUidOnce';

export type AuthContextType = {
  user: any;
  authError: string | null;
  loginUser: (email: string, password: string) => Promise<void>;
  logout: () => void;
  userLoading: boolean;
};

export const AuthContext = createContext<AuthContextType>({
  user: null,
  authError: null,
  loginUser: () => Promise.resolve(),
  logout: () => {},
  userLoading: true,
});

const AuthContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [userLoading, setUserLoading] = useState(true);
  const [authError, setAuthError] = useState<string | null>(null);
  const navigate = useNavigate();

  const loginUser = async (email: string, password: string) => {
    setAuthError(null);
    try {
      const res = await login({ email, password });
      setUser(res.data.data.user.user);
      await signInWithCustomToken(auth, res.data.data.token);
      const user: any = auth.currentUser;

      if (user.accessToken) {
        const idToken = user.accessToken;
        localStorage.setItem(ACCESS_TOKEN, idToken);
        localStorage.setItem(USER, JSON.stringify(res.data.data.user.user));
        navigate('/');
      }
    } catch (error) {
      setAuthError('Wrong credencials');
    }
  };

  onAuthStateChanged(auth, (userAuth: any) => {
    if (!user) {
      dbGetUserByUidOnce(userAuth?.uid, (snapshot: any) => {
        let newUser: any = {};
        newUser = snapshot.val();
        if (newUser.role === 'vendor') {
          setUser(newUser);
          setUserLoading(false);
        }
      });
    }
  });

  const logout = async () => {
    localStorage.removeItem(BEARER);
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(USER);
    setUser(null);
    navigate(ROUTES.LOGIN);
  };

  useEffect(() => {
    // set user from localstorage to be user details for navbar and other
    if (!!localStorage.getItem(USER)) {
      const userr: any = localStorage.getItem(USER) || {};
      if (JSON.parse(userr).role === 'staff') {
        setUser(JSON.parse(userr));
      } else {
        logout();
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, authError, loginUser, logout, userLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
