import { Typography } from '@mui/material';
import React from 'react';

import { InfoContainer, PageNameContainer, SmallCircle } from './RouteInfo.style';

interface RouteInfoDetailsProps {
  pageName?: string;
  description?: string;
  image?: any;
}

export const RouteInfoDetails: React.FC<RouteInfoDetailsProps> = ({
  pageName,
  description,
  image,
}) => (
  <PageNameContainer>
    {image && <SmallCircle>{React.cloneElement(image, { color: 'white' })}</SmallCircle>}
    <InfoContainer>
      <Typography variant="h5">{pageName}</Typography>
      <Typography>{description}</Typography>
    </InfoContainer>
  </PageNameContainer>
);
