import styled from '@emotion/styled';
import { Button, CircularProgress, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

export const FetchingNextPageButton = styled(Button)`
  background-color: #fff;
  width: 100%;
  margin-top: 16px;
  border-radius: 10px;
`;

export const FetchButton: React.FC<{ onView: () => void; isFetchingNextPage: boolean }> = ({
  onView,
  isFetchingNextPage,
}) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      onView?.();
    }
  }, [inView]);
  return (
    <FetchingNextPageButton disabled ref={ref}>
      {isFetchingNextPage ? (
        <Stack alignItems="center" direction="row">
          Loading more... <CircularProgress size={16} />
        </Stack>
      ) : (
        'No more items'
      )}
    </FetchingNextPageButton>
  );
};
