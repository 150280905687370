import { Divider } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardCircle, Flex } from '../../common';
import { FooterShape } from '../../components/FooterShape';

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { Links, links } from '../../components/RouteInfo/RouteInfo.constants';
import { Left, Right, VendorConsoleContainer } from './VendorConsole.style';

export const VendorConsole = () => {
  const navigate = useNavigate();

  return (
    <>
      <RouteInfo />
      <VendorConsoleContainer>
        <Flex>
          {links.map(
            (link: Links, index: number) =>
              !link.noInDashboard && (
                <DashboardCircle key={index} {...link} onClick={() => navigate(link?.link || '')} />
              ),
          )}
        </Flex>
      </VendorConsoleContainer>
      <FooterShape />
    </>
  );
};
