import { useDispatch } from 'react-redux';
import { setNotification } from '../store/slice';

export const useNotification = () => {
  const dispatch = useDispatch();

  const notify = (props: any) => dispatch(setNotification(props));

  const notifyError = (message: string) => dispatch(
    setNotification({ message, type: 'error' })
  );

  return {
    notify,
    notifyError
  };
};
