import { Box, styled } from '@mui/material';

export const VendorConsoleContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  padding: 4px 20px;
  position: relative;

  @media (max-width: 768px) {
    min-height: 135vh;
    flex-direction: column;
    overflow: auto;
  }
`;

export const Left = styled(Box)`
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${({ theme }) => theme.color.borderGrey};

  @media (max-width: 1024px) {
    border: none;
  }

  @media (max-height: 600px) {
    margin-top: 35px;
  }
`;

export const Right = styled(Box)<{ gap?: string }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    height: 100%;
    align-items: stretch;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 0 0 50%;
    gap: 11px;
  }
`;
