export enum ROUTES {
  LOGIN = '/login',
  DASHBOARD = '/',
  OVERVIEW = '/overview',
  INVENTORY = '/inventory',
  ORDERS = '/orders',
  ORDER_DETAIL = '/order-detail',
  ANALYTICS = '/analytics',
  MAIL = '/mail',
  MAIL_PAGE = '/mail/detail',
  ANALYTICS_ORDERS = '/analytics/orders',
  ANALYTICS_TRANSACTIONS = '/analytics/transactions',
  ANALYTICS_PRODUCTS = '/analytics/products',
  TRANSACTIONS = '/transactions',
  TERMS = '/terms',
  POLICY = '/privacy-policy',
  SETTIGNS = '/settings',
  INTERNAL_SUPPORT = '/internal-support',
  INTERNAL_SUPPORT_TICKET = '/internal-support/ticket',
  SUPPORT_ITEM = '/support-item',
  NOT_FOUND = '*',
}

export const DATE_TIME = 'hh:mm';
export const DATE_FULL_MONTH = 'MMMM, dd, yyyy';
export const DATE_FULL_MONTH_ORDER = 'MMMM dd, yyyy';
export const DATE_CLASSIC = 'MM.dd.yyyy';
export const EU_DATE = 'dd.MM.yyyy';
