import { Tab } from '@mui/material';
import React, { useState } from 'react';
import { CustomButton } from '../../common';
import { Filter } from '../../svg/Filter';
import { FiltersContainer, TabsContainer } from './Transactions.style';

interface TransactionsControlProps {
  tabs: { name: string; value: string }[];
  value: number;
  openFilter: () => void;
  changeValue: (event: any, newValue: number) => void;
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const TransactionsControl: React.FC<TransactionsControlProps> = ({
  tabs,
  value,
  changeValue,
  openFilter,
}) => {
  return (
    <FiltersContainer>
      <TabsContainer width="75%" value={value} onChange={changeValue}>
        {tabs.map((item: { name: string; value: string }, index: number) => (
          <Tab key={index} label={item.name} {...a11yProps(index)} />
        ))}
      </TabsContainer>

      <CustomButton height="56px" content={<Filter />} onClick={openFilter} />
    </FiltersContainer>
  );
};
