import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Icon, Stack } from '@mui/material';
import React from 'react';

import { CustomText } from '../../styles';
import { InventoryListContent, InventoryListHeaderContainer } from './Inventory.style';

export const InventoryListHeader = () => (
  <InventoryListHeaderContainer>
    <InventoryListContent width={19.6}>
      <Stack direction="row">
        <CustomText bold={700}>Product Name</CustomText>
      </Stack>
    </InventoryListContent>
    <InventoryListContent width={23.4}>
      <CustomText bold={700}>Category</CustomText>
    </InventoryListContent>
    <InventoryListContent width={13.7}>
      <CustomText bold={700}>SKU</CustomText>
    </InventoryListContent>
    <InventoryListContent width={14}>
      <CustomText bold={700}>Stock</CustomText>
    </InventoryListContent>
    <InventoryListContent width={20}>
      <CustomText bold={700}>Price</CustomText>
    </InventoryListContent>
  </InventoryListHeaderContainer>
);
