import { AccountCircle } from '@mui/icons-material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';
import { AuthContext } from '../../context/authContext';
import { ROUTES } from '../../constants';
import { Logo } from './Logo';
import {
  LeftContainer,
  MuiIconContainer,
  NavContainer,
  UserContainer,
  UserName,
} from './Nav.style';
// svg
import { NavArrow } from '../../svg/NavArrow';
import { FullScreen } from '../../svg/FullScreen';

interface NavProps {
  logout: () => void;
}

export const Nav: React.FC<NavProps> = ({ logout }) => {
  const { user } = useContext(AuthContext);

  return (
    <NavContainer>
      <LeftContainer>
        <Logo />
      </LeftContainer>
      <LeftContainer gap={9} align="flex-end">
        <UserContainer
          onClick={() => {
            logout();
          }}
        >
          <MuiIconContainer height={37} width={37}>
            <AccountCircle />
          </MuiIconContainer>
          <UserName>{user && user.name}</UserName>
          <NavArrow />
        </UserContainer>
        <MuiIconContainer height={30} width={30}>
          <FullScreen />
        </MuiIconContainer>
      </LeftContainer>
    </NavContainer>
  );
};
