import React from 'react';

export const MailIcon: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg width="58" height="54" viewBox="0 0 58 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M57.3048 19.6667C57.3048 17.6267 56.2565 15.8417 54.6415 14.85L31.8615 1.505C30.1048 0.456667 27.8948 0.456667 26.1382 1.505L3.35817 14.85C1.74317 15.8417 0.666504 17.6267 0.666504 19.6667V48C0.666504 51.1167 3.2165 53.6667 6.33317 53.6667H51.6665C54.7832 53.6667 57.3332 51.1167 57.3332 48L57.3048 19.6667ZM25.9965 31.9633L5.5965 19.2133L26.1382 7.17167C27.8948 6.12333 30.1048 6.12333 31.8615 7.17167L52.4032 19.2133L32.0032 31.9633C30.1615 33.0967 27.8382 33.0967 25.9965 31.9633Z"
        fill={color ? color : '#0C84FC'}
      />
    </svg>
  );
};
