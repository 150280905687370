import React from 'react';

export const SettingsInfo = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 0C4.92039 0 0 4.91975 0 11C0 17.0796 4.91975 22 11 22C17.0796 22 22 17.0802 22 11C22 4.92039 17.0802 0 11 0ZM11 20.4651C5.78093 20.4651 1.53489 16.2191 1.53489 11C1.53489 5.78093 5.78093 1.53489 11 1.53489C16.2191 1.53489 20.4651 5.78093 20.4651 11C20.4651 16.2191 16.2191 20.4651 11 20.4651Z"
        fill="#0C84FC"
      />
      <path
        d="M10.9999 8.74902C10.576 8.74902 10.2324 9.09264 10.2324 9.51644V15.8095C10.2324 16.2333 10.576 16.5769 10.9999 16.5769C11.4237 16.5769 11.7673 16.2333 11.7673 15.8095V9.51649C11.7673 9.09264 11.4237 8.74902 10.9999 8.74902Z"
        fill="#0C84FC"
      />
      <path
        d="M10.9999 5.42334C10.576 5.42334 10.2324 5.76696 10.2324 6.19076V7.11171C10.2324 7.53555 10.576 7.87913 10.9999 7.87913C11.4237 7.87913 11.7673 7.53551 11.7673 7.11171V6.19076C11.7673 5.76696 11.4237 5.42334 10.9999 5.42334Z"
        fill="#0C84FC"
      />
    </svg>
  );
};
