import { Box, styled } from '@mui/material';

export const LoadingContainer = styled(Box)`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  position: absolute;
  top: 0;
  left: 0;
`;

export const LoadingFullContainer = styled(Box)`
  width: 100vw;
  position: fixed;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9999999;
  margin: 0 !important;
`;
