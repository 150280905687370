import { styled, Box } from '@mui/material';

export const WhiteContainer = styled(Box)<{
  jcontent?: string;
  gap?: number;
  padding?: string;
  margin?: string;
  noFlex?: boolean;
  noHeight?: boolean;
}>`
  width: 100%;
  flex: ${({ noFlex, noHeight }) => (noFlex || noHeight ? 0 : 1)};
  display: flex;
  align-items: center;
  justify-content: ${({ jcontent }) => jcontent || 'space-between'};
  gap: ${({ gap }) => `${gap}px` || '0'};
  padding: ${({ padding }) => padding || '20px'};
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  background-color: ${({ theme }) => theme.palette.common.white};
  margin: ${({ margin }) => margin || '0'};
  position: relative;
  height: ${({ noHeight }) => (noHeight ? 'fit-content' : '100%')};

  &:hover {
    outline: 1px solid ${({ theme }) => theme.color.lightBlue};
  }

  svg {
    cursor: pointer;
    transition: all 0.4s;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
