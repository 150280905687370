import React from "react";

import { CustomText, TableContent, TableHeader } from "../../styles";

export const OrderDetailHeader = () => (
  <TableHeader>
    <TableContent width={28.75}>
      <CustomText bold={700}>Product Name</CustomText>
    </TableContent>
    <TableContent width={17}>
      <CustomText bold={700}>Category</CustomText>
    </TableContent>
    <TableContent width={23.75}>
      <CustomText bold={700}>Qty</CustomText>
    </TableContent>
    <TableContent width={24}>
      <CustomText bold={700}>Price</CustomText>
    </TableContent>
    <TableContent width={20}>
      <CustomText bold={700}>Total price</CustomText>
    </TableContent>
  </TableHeader>
)
