import { Box, TextField } from '@mui/material';
import React from 'react';

import { useStudents } from '../../hooks/useStudents';
import { CustomText } from '../../styles';
import { CustomAutocomplete } from './Autocomplete.style';

interface StudentAutoCompleteProps {
  setSelectedStudent?: any;
}

export const StudentAutoComplete: React.FC<StudentAutoCompleteProps> = ({ setSelectedStudent }) => {
  const { students } = useStudents();

  return (
    <Box>
      <CustomText bold={700} margin="0 0 3% 0">
        Student Name
      </CustomText>
      <CustomAutocomplete
        options={students}
        loadingText="Loading"
        freeSolo
        disableClearable
        getOptionLabel={(option: any) => `${option?.name}` || ''}
        onChange={(_, value: any) => {
          setSelectedStudent(value.id);
        }}
        renderInput={(params) => <TextField {...params} label="Select" />}
      />
    </Box>
  );
};
