import { useTheme } from '@mui/material';
import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { Flex } from '../../common';
import { CustomText } from '../../styles';
import { ProductCard } from './Overview.style';

interface ProductType {
  totalCount: number;
  totalPrice: number;
  totalOrders: number;
  product: {
    id: string;
    categoryHexColor: string;
    name: string;
    price: number;
    quantity: number;
    categoryName: string;
  };
}

export const TopProductCard: React.FC<ProductType> = ({
  totalCount,
  totalPrice,
  totalOrders,
  product,
}) => {
  const theme = useTheme();
  const data = [
    { name: 'Sold', value: totalCount, color: `#${product.categoryHexColor}` },
    { name: 'Left', value: totalOrders, color: theme.color.borderGrey },
  ];
  return (
    <ProductCard>
      <Flex column fullHeight gap="10px">
        <Flex fullHeight column gap="5px">
          <CustomText>40%</CustomText>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius="100%"
                dataKey="value"
              >
                {data.map((item: any, index: number) => (
                  <Cell key={item.name} fill={item.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Flex>
        <Flex contectCenter column noFlex gap="5px">
          <CustomText bold={700}>{product.name}</CustomText>
          <CustomText>{product.categoryName}</CustomText>
        </Flex>
        <CustomText bold={700} color={theme.background.blue}>
          £{product.price / 100}
        </CustomText>
      </Flex>
    </ProductCard>
  );
};
