import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Stack } from "@mui/material";
import React, { ReactElement } from "react";

import { CustomText } from "../../styles";
import { SectionHeaderContainer } from "./SectionHeaderInfo.style";

interface SectionHeaderInfoProps {
  banner: string | ReactElement;
  onClick?: () => void;
  content?: ReactElement;
}

export const SectionHeaderInfo: React.FC<SectionHeaderInfoProps> = ({
  banner,
  onClick,
  content
}) => (
  <SectionHeaderContainer>
    <Stack direction="row" alignItems="center">
      {onClick && (
        <IconButton size="large" onClick={onClick}>
          <ArrowBackIcon />
        </IconButton>
      )}
      <CustomText fontSize={1.4} bold={700}>
        {banner}
      </CustomText>
    </Stack>
    {content}
  </SectionHeaderContainer>
)
