import React from 'react';

export const Overview = () => (
  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 30H21C22.65 30 24 28.65 24 27V3C24 1.35 22.65 0 21 0H3C1.35 0 0 1.35 0 3V27C0 28.65 1.35 30 3 30ZM3 54H21C22.65 54 24 52.65 24 51V39C24 37.35 22.65 36 21 36H3C1.35 36 0 37.35 0 39V51C0 52.65 1.35 54 3 54ZM33 54H51C52.65 54 54 52.65 54 51V27C54 25.35 52.65 24 51 24H33C31.35 24 30 25.35 30 27V51C30 52.65 31.35 54 33 54ZM30 3V15C30 16.65 31.35 18 33 18H51C52.65 18 54 16.65 54 15V3C54 1.35 52.65 0 51 0H33C31.35 0 30 1.35 30 3Z"
      fill="white"
    />
  </svg>
);
