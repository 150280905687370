import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { Flex } from '../../../common';
import { CustomText } from '../../../styles';
import { MailMainInfoContainer } from '../Mail.style';

interface ExternalSupportTicketDetailProps {
  onClick: () => void;
}

export const MailPageDetail: React.FC<ExternalSupportTicketDetailProps> = ({ onClick }) => (
  <MailMainInfoContainer>
    <Flex>
      <IconButton onClick={onClick}>
        <ArrowBack />
      </IconButton>
      <CustomText fontSize={1.2} bold={700}>
        Mail Detail
      </CustomText>
    </Flex>
  </MailMainInfoContainer>
);
