import { useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { CustomModal } from '../../components/CustomModal/CustomModal';
import { Layout } from '../../components/Layout/Layout';
import { Logout } from '../../components/Logout/Logout';
import { Nav } from '../../components/Nav/Nav';
import { decodeUrl } from '../../helpers';

export const Dashboard = () => {
  const [showLogout, setShowLogout] = useState(false);
  const { pathname } = useLocation();
  const matches = useMediaQuery('(max-width: 1024px)');
  const checkPath = decodeUrl(pathname).match(/overview/gi);

  const handleCloseLogout = () => setShowLogout(false);
  const handleOpenLogout = () => setShowLogout(true);

  return (
    <Layout noScroll={matches && checkPath ? 'hidden' : 'auto'}>
      <Nav logout={handleOpenLogout} />
      {showLogout && (
        <CustomModal
          isOpen={showLogout}
          onClose={handleCloseLogout}
          content={() => <Logout onClose={handleCloseLogout} />}
          banner="Logout"
        />
      )}
      <Outlet />
    </Layout>
  );
};
