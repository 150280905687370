import React, { ReactNode } from 'react';
import { Banner, Circle, CircleContainer, Desc } from './DashboardCircle.style';

export interface DashboardCircleProps {
  image: ReactNode | string;
  banner: string;
  descriptionText?: string;
  invert?: boolean;
  height?: string;
  width?: string;
  margin?: string;
  onClick?: () => void;
}

export const DashboardCircle: React.FC<DashboardCircleProps> = ({
  image,
  banner,
  descriptionText,
  invert,
  height,
  width,
  margin,
  onClick,
}) => (
  <CircleContainer invert={invert} height={height} width={width} margin={margin} onClick={onClick}>
    <Circle invert={invert}>{image}</Circle>
    <Banner variant="h5">{banner}</Banner>
    <Desc>{descriptionText}</Desc>
  </CircleContainer>
);
