import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import React from 'react';
import { CustomButton } from '../../common';
import { Flex } from '../../common';
import { CustomText } from '../../styles';

interface InternalSupportTicketDetailProps {
  agent?: string | null;
  onClick?: () => void;
}

export const InternalSupportTicketDetail: React.FC<InternalSupportTicketDetailProps> = ({
  agent,
  onClick,
}) => (
  <Flex>
    <Flex>
      <IconButton onClick={onClick}>
        <ArrowBackIcon />
      </IconButton>
      <CustomText fontSize={1.2} bold={700}>
        Message Details
      </CustomText>
    </Flex>
    <Flex flexEnd gap="5px" p="0 20">
      {agent && (
        <>
          <CustomText bold={700}>Agent:</CustomText>
          <CustomText>{agent}</CustomText>
        </>
      )}
    </Flex>
  </Flex>
);
