export interface AnalyticsLink {
  name: string;
  link: string;
  image?: string;
}

export const analyticLinks: AnalyticsLink[] = [
  {
    name: 'EARNINGS',
    link: '/analytics',
    image: '/wallet-sm.png',
  },
  {
    name: 'TRANSACTIONS',
    link: '/analytics/transactions',
    image: '/transactions-sm.png',
  },
  {
    name: 'PRODUCTS',
    link: '/analytics/products',
    image: '/products-sm.png',
  },
];
