import React, { useEffect, useState } from 'react';
import { Flex } from '../../../../common';
import { ProductCountCard } from './ProductCountCard';
import { ProductIcon } from '../../../../svg/ProductIcon';
import { Folder } from '../../../../svg/Folder';
import { getAnalytics } from '../../../../api';

export const ProductCountCards = () => {
  const [value, setValue] = useState({
    totalCategoriesCount: 0,
    totalProductsCount: 0,
  });
  const getData = async () => {
    const data = await getAnalytics('products/total');
    setValue(data?.data.data);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Flex noFlex>
      <ProductCountCard
        image={<Folder />}
        topText="Total"
        bottomText="Categories"
        totalCount={value?.totalCategoriesCount}
      />
      <ProductCountCard
        image={<ProductIcon />}
        topText="Total"
        bottomText="Products"
        totalCount={value?.totalProductsCount}
      />
    </Flex>
  );
};
