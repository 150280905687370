import React from 'react';

import { DashboardCircleProps } from '../../common';
import { ROUTES } from '../../constants';
import { AnalyticIcon } from '../../svg/AnalyticIcon';
import { InfoIcon } from '../../svg/InfoIcon';
import { Inventory } from '../../svg/Inventory';
import { MailIcon } from '../../svg/MailIcon';
import { OrdersIcon } from '../../svg/OrdersIcon';
import { Overview } from '../../svg/Overview';
import { SettingsIcon } from '../../svg/SettingsIcon';
import { SupportIcon } from '../../svg/SupportIcon';
import { TransactionsIcon } from '../../svg/TransactionsIcon';

export interface Links extends DashboardCircleProps {
  link?: string;
  smImage?: string;
  noDesc?: boolean;
  btnOption?: string;
  invert: boolean;
  image: any;
  noInDashboard?: boolean;
}

export const links: Links[] = [
  {
    image: <Overview />,
    banner: 'Overview',
    descriptionText: 'Manage your transactions',
    link: ROUTES.OVERVIEW,
    smImage: '/overview-sm.png',
    invert: false,
  },
  {
    image: <Inventory />,
    banner: 'Inventory',
    descriptionText: 'Manage products in your inventory',
    link: ROUTES.INVENTORY,
    smImage: '/inventory-sm.png',
    btnOption: 'Add Product',
    invert: true,
  },
  {
    image: <AnalyticIcon />,
    banner: 'Analytics',
    descriptionText: 'View meaningful data for effective decision making',
    link: ROUTES.ANALYTICS,
    smImage: '/analytics-sm.png',
    invert: true,
  },
  {
    image: <TransactionsIcon />,
    banner: 'Transactions',
    descriptionText: 'Manage your transactions',
    link: ROUTES.TRANSACTIONS,
    smImage: '/transactions-sm.png',
    invert: true,
  },
  {
    image: <MailIcon />,
    banner: 'Mail',
    descriptionText: 'Connect instantly with your organisation',
    link: ROUTES.MAIL,
    smImage: '/transactions-sm.png',
    invert: true,
    btnOption: 'Compose',
  },
  {
    image: <SettingsIcon />,
    banner: 'Settings',
    descriptionText: 'Manage your profile details',
    link: ROUTES.SETTIGNS,
    smImage: '/transactions-sm.png',
    invert: true,
  },
  {
    image: <MailIcon />,
    banner: 'Internal support',
    descriptionText: 'Manage your profile details',
    link: ROUTES.INTERNAL_SUPPORT,
    smImage: '/transactions-sm.png',
    invert: true,
    noInDashboard: true,
    btnOption: 'Create ticket',
  },
  {
    image: <InfoIcon />,
    banner: 'Terms & Conditions',
    descriptionText: 'Service are the legal agreements',
    link: ROUTES.TERMS,
    smImage: '/transactions-sm.png',
    invert: true,
    noInDashboard: true,
  },
  {
    image: <InfoIcon />,
    banner: 'Privacy Policy',
    descriptionText: 'Statement or legal document',
    link: ROUTES.POLICY,
    smImage: '/transactions-sm.png',
    invert: true,
    noInDashboard: true,
  },
];
