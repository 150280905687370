import { stringLength } from '@firebase/util';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getTicket, sendReply } from '../api';

export const useTicket = (id: string) => {
  const [ticket, setTicket] = useState<null | {
    agent: null | {
      name: string;
    };
    createdBy: { uid: string; name: string; imageUrl: string };
    date: number;
    exportExternal: boolean;
    id: string;
    resolved: boolean;
    messages: {
      body: string;
      date: number;
      from: { photo: string; name: string; email: string };
      id: string;
      isFromExternalSupport: boolean;
      subject: string;
    }[];
  }>(null);
  const { data, isLoading, refetch } = useQuery(['ticket', id], () => getTicket(id));

  const reply = async (message: string) => {
    const res = await sendReply(id, message);
    if (res.status === 200) {
      refetch();
      return res;
    }
  };

  useEffect(() => {
    if (data) {
      setTicket(data.data.data);
    }
  }, [data]);

  return {
    ticket,
    isLoading,
    reply,
  };
};
