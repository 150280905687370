import { useState, useEffect } from 'react';
import { useQuery, useInfiniteQuery } from 'react-query';
import { getTransactions, getTransactionsTotal } from '../api';

export type TransactionType = 'wallet' | 'SchoolBill';

export const useTransactions = (params?: any) => {
  const [transactions, setTransactions] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const { data: total, isLoading: totalLoading } = useQuery(['totalPrice', params], () =>
    getTransactionsTotal(params),
  );

  const { data, error, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['transactions', params],
      async ({ pageParam = 1 }) => {
        const res = await getTransactions({
          page: pageParam,
          pageCount: 20,
          from: 0,
          to: 19000000,
          ...params,
        });
        return res;
      },
      {
        getNextPageParam: (lastPage, pages) => pages.length + 1,
      },
    );

  useEffect(() => {
    if (data) {
      const arr: any = data?.pages.map((item: any) => ({ ...item?.data?.data?.transactions }));
      setTransactions(Object.values(arr));
    }
  }, [data]);

  useEffect(() => {
    if (total) {
      setTotalPrice(total?.data?.data?.totalAmount);
    }
  }, [total]);

  return {
    transactions,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    totalPrice,
  };
};
