import React from 'react';
import { Flex } from '../../common';
import { CustomText } from '../../styles';

export const MailListHeader = () => {
  return (
    <Flex gap="0px" p="0px">
      <Flex flex={15}></Flex>
      <Flex flex={20}>
        <CustomText bold={700}>Subject</CustomText>
      </Flex>
      <Flex flex={10}>
        <CustomText bold={700}>Date</CustomText>
      </Flex>
      <Flex flex={10}>
        <CustomText bold={700}>Time</CustomText>
      </Flex>
    </Flex>
  );
};
