import React from 'react';
import moment from 'moment';
import { styled, Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { CustomText } from '../../styles';
import { SmallCircle } from '../RouteInfo/RouteInfo.style';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';

export const TitleContainer = styled(Box)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.borderGrey};
`;

export const TitleTextAndIcon = styled(Box)`
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
`;

export const TitleInnerContainer = styled(Box)`
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ChartTitleFilter = styled(CustomText)`
  text-transform: capitalize;
`;

interface ChartTitleTypes {
  title: string;
  date?: number | null;
  year?: number;
  color?: string;
  icon?: any;
  iconColor?: string;
  onClick?: () => void;
  filter?: any;
  description?: string;
  withoutButton?: boolean;
  children?: React.ReactNode;
  initialFilter?: any;
  clearFilter?: () => void;
  realFilter?: any;
}

export const ChartTitle: React.FC<ChartTitleTypes> = ({
  title,
  year,
  date,
  color,
  icon,
  iconColor,
  onClick,
  filter,
  description,
  withoutButton,
  initialFilter,
  clearFilter,
  realFilter,
  children,
}) => {
  return (
    <TitleContainer>
      <TitleInnerContainer>
        <TitleTextAndIcon>
          {icon && (
            <SmallCircle color={color}>
              {React.cloneElement(icon, { color: iconColor ? iconColor : 'white' })}
            </SmallCircle>
          )}
          <div>
            <CustomText bold={700} fontSize={1.2}>
              {title}
            </CustomText>
            <ChartTitleFilter bold={400} fontSize={0.9}>
              {filter &&
                Object.keys(filter).map((type: string, i: number) => {
                  if (i < Object.keys(filter).length - 1) {
                    if (filter[type]) {
                      return filter[type] + ' / ';
                    }
                    if (type === 'category') {
                      return 'All categories / ';
                    }
                    if (type === 'product') {
                      return 'All products /';
                    }
                    return 'all ' + type + 's / ';
                  } else {
                    if (filter[type]) {
                      return filter[type];
                    }
                    if (type === 'category') {
                      return 'All categories';
                    }
                    if (type === 'product') {
                      return 'All products';
                    }
                    return 'all ' + type + 's';
                  }
                })}
              {description && description}
            </ChartTitleFilter>
          </div>
        </TitleTextAndIcon>
        {!withoutButton &&
          (_.isEqual(realFilter, initialFilter) ? (
            <IconButton size="large" onClick={onClick}>
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton size="large" onClick={clearFilter}>
              <CloseIcon />
            </IconButton>
          ))}
        {children}
      </TitleInnerContainer>
    </TitleContainer>
  );
};
