import axios, { AxiosRequestConfig } from 'axios';
import { auth } from './db';

export const ACCESS_TOKEN: string = 'ac_token';
export const BEARER: string = 'Bearer';
export const REFRESH_TOKEN: string = 'refresh_token';
export const USER: string = 'user';

const Axios = axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    'Content-Type': 'application/json',
    mode: 'no-cors',
  },
});

Axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;

const refreshToken = async () => {
  try {
    if (auth.currentUser) {
      const newToken = await auth.currentUser.getIdToken(true);
      Axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
      localStorage.setItem(ACCESS_TOKEN, newToken);
      return newToken;
    }
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(USER);
    window.location.reload();
  } catch (err: any) {
    if (err.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('uid');
      window.location.reload();
    }
  }
};

Axios.interceptors.response.use(
  (response: any) => response,
  async (e: any) => {
    if (e.response.status === 401) {
      const token: string | undefined = await refreshToken();
      if (token) {
        const newReq = e.config;
        newReq.headers.Authorization = `Bearer ${token}`;
        return Axios.request(newReq);
      }
    } else {
      return e.response;
    }
  },
);

export default Axios;
