import { Box, CircularProgress, TextField } from '@mui/material';
import React from 'react';

import { CustomText } from '../../styles';
import { CustomAutocomplete } from './Autocomplete.style';
import { useProducts } from '../../hooks/useProducts';

interface ProductAutocompleteProps {
  onChange: (value: string, price: number) => void;
}

export const ProductAutocomplete: React.FC<ProductAutocompleteProps> = ({ onChange }) => {
  const { products } = useProducts();

  if (!products || products.length <= 0) return <CircularProgress />;

  return (
    <Box>
      <CustomText bold={700} margin="0 0 3% 0">
        Products
      </CustomText>
      <CustomAutocomplete
        options={Object.values(products[0]).map(({ id, name, price }: any) => ({
          id,
          name,
          price,
        }))}
        loadingText="Loading"
        freeSolo
        disableClearable
        getOptionLabel={(option: any) => `${option?.name}` || ''}
        renderInput={(params) => <TextField {...params} label="Select" />}
      />
    </Box>
  );
};
