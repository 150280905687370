import { styled, Box } from '@mui/material';

export const TransactionsAnalyticsContainer = styled(Box)`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
`;

export const Right = styled(Box)<{ gap?: number }>`
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
`;
