import { Stack, useTheme } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "../../constants";
import { CustomText } from "../../styles";
import { NotFoundContainer } from "./NotFound.style";

export const NotFound = () => {
  const theme = useTheme();

  return (
    <NotFoundContainer>
      <CustomText fontSize={9} color={theme.color.lightBlue}>
        404
      </CustomText>
      <Stack direction="row" spacing={1}>
        <CustomText fontSize={1.2} bold={300}>
          Page not found...
        </CustomText>
        <CustomText fontSize={1.2} bold={300}>
          <Link to={ROUTES.DASHBOARD}>
            Go home
          </Link>
        </CustomText>
      </Stack>
    </NotFoundContainer>
  )
}
