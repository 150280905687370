import React from 'react';

export const DownloadLink: React.FC<{ link: string; children: any }> = ({ link, children }) => {
  return (
    <a
      style={{ textDecoration: 'none' }}
      href={link}
      rel="noopener noreferrer"
      target="_blank"
      download
    >
      {children}
    </a>
  );
};
