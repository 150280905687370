import { Box, styled, TextField } from '@mui/material';

export const AddOrderInfo = styled(Box)<{ gap?: number }>`
  height: 41%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ gap }) => `${gap}px`};
  padding-bottom: 25px;
  border-bottom: 1px solid ${({ theme }) => theme.color.divider};
  position: relative;

  div {
    flex: 0 0 50%;
  }
`;

export const AddOrderProductContainer = styled(Box)<{ gap?: number }>`
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ gap }) => `${gap}px`};
  padding-bottom: 25px;
  margin-top: 44px;
  position: relative;

  div {
    flex: 0 0 33%;

    &:first-of-type {
      flex: 0 0 50%;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    border-radius: 7px;
  }
`;

export const AddCircleContainer = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 2%;
  position: relative;
`;

export const AddCircle = styled(Box)`
  height: 46px;
  width: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto !important;
  color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;
  border-radius: 50%;
  background: ${({ theme }) => theme.background.blue};
`;

export const CalendarButton = styled(TextField)`
  height: 41px;
  width: 43px;
  border: none;
  outline: none;
  background-color: ${({ theme }) => theme.background.blue};

  & .MuiButtonBase-root {
    background: ${({ theme }) => theme.background.blue};
    border-radius: 10px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -62%);

    @media(max-width: 1024px) {
      transform: translate(-100%, -100%);
    }
  }

  fieldset {
    display: none;
  }
`;
