import { Box, FormControl, FormControlLabel, Stack, Switch } from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';

import { CustomButton } from '../../../common';
import { StudentAutoComplete } from '../../../components/Autocomplete/StudentAutocomplete';
import { DATE_FULL_MONTH_ORDER, DATE_TIME } from '../../../constants';
import { CustomText } from '../../../styles';
import { AddOrderInfo } from '../Orders.style';
import { AddOrderProduct } from './AddOrderProduct';

interface AddProductType {
  productId: string;
  quantity: number;
}

export const AddOrder: React.FC<{ mutation: any; mutationOnHold: any }> = ({
  mutation,
  mutationOnHold,
}) => {
  const [selectedStudent, setSelectedStudent] = useState('');
  const [onHold, setOnHold] = useState(false);
  const [prices, setPrices] = useState([]);
  const [addProducts, setAddProducts] = useState<AddProductType[]>([
    {
      productId: '',
      quantity: 1,
    },
  ]);

  const addProductRow = () => {
    setAddProducts([
      ...addProducts,
      {
        productId: '',
        quantity: 1,
      },
    ]);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    const baseData = {
      studentId: selectedStudent,
      products: addProducts,
    };
    if (onHold) {
      mutationOnHold.mutate(baseData);
    } else {
      mutation.mutate({ ...baseData, schoolBill: true });
    }
  };

  return (
    <Stack
      component="form"
      height="100%"
      width="100%"
      padding="29px 25px"
      direction="column"
      alignItems="center"
      justifyContent="space-evenly"
      onSubmit={onSubmit}
    >
      <AddOrderInfo gap={4}>
        <Box>
          <Stack direction="row" margin="1% 0" spacing={1}>
            <CustomText bold={700}>Order ID:</CustomText>
            <CustomText>#{Math.floor(Math.random() * 500)}</CustomText>
          </Stack>
          <Stack direction="row" margin="1% 0" spacing={1}>
            <CustomText bold={700}>Order Date:</CustomText>
            <CustomText>{format(new Date(), DATE_FULL_MONTH_ORDER)}</CustomText>
          </Stack>
          <Stack direction="row" margin="1% 0" spacing={1}>
            <CustomText bold={700}>Order Time:</CustomText>
            <CustomText>{format(new Date(), DATE_TIME)}</CustomText>
          </Stack>
        </Box>
        <Box>
          <StudentAutoComplete setSelectedStudent={setSelectedStudent} />
        </Box>
      </AddOrderInfo>

      {addProducts.map((product: any, index: number) => (
        <AddOrderProduct
          key={index}
          index={index}
          addProductRow={addProductRow}
          setPrices={setPrices}
          addProducts={addProducts}
          setAddProducts={setAddProducts}
        />
      ))}

      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        margin="35px 0 0 0"
        spacing={1}
      >
        <CustomText>Total Order Price: </CustomText>
        <CustomText fontSize={1.4} bold={700}>
          £14
        </CustomText>
      </Stack>

      <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
        <FormControl>
          <FormControlLabel
            control={
              <Switch checked={onHold} onChange={() => setOnHold((prevState) => !prevState)} />
            }
            label={onHold ? 'On Hold' : ''}
          />
        </FormControl>
        <CustomButton content="Create" width="30%" type="submit" margin="4% 0" />
      </Stack>
    </Stack>
  );
};
