import { Box, Icon, styled } from '@mui/material';

export const MailListItem = styled(Box)<{ padding?: string; cursor?: string }>`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: ${({ padding }) => padding || '20px 10px'};
  border-radius: 10px;
  cursor: ${({ cursor }) => cursor};
  position: relative;

  &:hover {
    outline: 1px solid ${({ theme }) => theme.color.lightBlue};
  }

  @media (max-width: 1024px) {
    align-items: flex-start;
    flex-direction: column;

    div:last-of-type {
      justify-content: flex-end;
      position: absolute;
      top: 50%;
      right: 35px;
      transform: translateY(-50%);
    }
  }
`;

export const Marker = styled(Box)<{ bc?: boolean }>`
  height: 75%;
  width: 6px;
  border-radius: 0 6px 6px 0;
  background-color: ${({ theme, bgcolor }) => bgcolor && theme.palette.primary.light};
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

export const MailListItemContent = styled(Box)<{ flex: number; margin?: string; end?: boolean }>`
  height: 100%;
  flex: ${({ flex }) => flex};
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin || '0'};
  position: relative;
  justify-content: ${({ end }) => (!end ? 'flex-start' : 'flex-end')};

  @media (max-width: 1024px) {
    width: 90%;
  }
`;

export const MuiIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.borderGrey};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.background.blue};
  }
`;

export const MailMainInfoContainer = styled(Box)<{
  width?: string;
  noResponsive?: boolean;
  margin?: string;
  noBorder?: boolean;
}>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border: ${({ noBorder, theme }) => (noBorder ? 'none' : '1px solid ' + theme.color.borderGrey)}};
  border-left: none;
  border-right: none;
  margin: ${({ margin }) => margin};

  @media (max-width: 768px) {
    flex-direction: ${({ noResponsive }) => (noResponsive ? 'row' : 'column')};
  }
`;
