import {
  Box,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from '@mui/material';
import React from 'react';

import { CustomText } from '../../styles';
import { InputWrapper } from './InputWrapper';

const InputContainer = styled(Box)<{ width?: string; alignitems?: string }>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  align-items: ${({ alignitems }) => alignitems || 'center'};
  justify-content: center;
  flex-direction: column;

  & .Mui-focused {
    outline-color: blue;
  }
`;

export interface InputFieldProps {
  info?: string;
  id?: string;
  alignitems?: string;
  width?: string;
  padding?: string;
  margin?: string;
  placeholder?: string;
  value?: any;
  options?: { name: string; value: any }[];
  [x: string]: any;
}

export const CustomInput = styled(TextField)<{
  padding?: string;
  marginInput?: string;
}>`
  width: 100%;
  border-radius: 10px;
  margin: ${({ marginInput }) => marginInput};
`;

export interface InputFieldProps {
  info?: string;
  id?: string;
  alignitems?: string;
  width?: string;
  padding?: string;
  margin?: string;
  placeholder?: string;
  value?: any;
  options?: { name: string; value: any }[];
}

export const InputField: React.FC<InputFieldProps> = ({
  info,
  id,
  width,
  alignitems,
  padding,
  margin,
  ...rest
}) => (
  <InputWrapper width={width} alignitems={alignitems} info={info}>
    <CustomInput id={id} padding={padding} {...rest} />
  </InputWrapper>
);

const SelectContainer = styled(Box)<{ width?: string; alignitems?: string }>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  align-items: ${({ alignitems }) => alignitems || 'center'};
  justify-content: center;
  flex-direction: column;

  & .MuiFormControl-root {
    width: 100%;
  }
`;

export const CustomSelect = styled(Select)<{
  pty?: boolean;
  padding?: string;
  marginInput?: string;
}>`
  width: 100%;
  color: ${({ theme, pty }) => (pty ? theme.palette.grey[500] : theme.palette.common.black)};
  margin: ${({ marginInput }) => marginInput};
`;

export const SelectField: React.FC<InputFieldProps> = ({
  info,
  id,
  width,
  alignitems,
  padding,
  margin,
  children,
  placeholder,
  value,
  options,
  ...rest
}) => (
  <InputWrapper width={width} alignitems={alignitems} info={info}>
    <FormControl style={{ width: '100%' }}>
      <CustomSelect labelId={id} pty={!value} {...rest} value={value || 'none'}>
        {placeholder && (
          <option value="none" disabled>
            {placeholder}
          </option>
        )}
        {options &&
          options.map((item: any, index: number) => (
            <MenuItem key={index} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        {children}
      </CustomSelect>
    </FormControl>
  </InputWrapper>
);

const ShadowStyledField = styled(Input)<{ padding?: string; marginInput?: string }>`
  width: 100%;
  border-radius: 10px;
  padding: ${({ padding }) => (padding ? padding : '4px 10px')};
  background-color: ${({ theme }) => theme.palette.common.white};
  margin: ${({ marginInput }) => marginInput};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
`;

export const ShadowInputField: React.FC<InputFieldProps> = ({
  info,
  id,
  width,
  alignitems,
  padding,
  margin,
  ...rest
}) => (
  <InputWrapper width={width} alignitems={alignitems} info={info}>
    <ShadowStyledField disableUnderline padding={padding} {...rest} />
  </InputWrapper>
);
