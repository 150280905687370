import React from 'react';

export const Inventory: React.FC<{ color?: string }> = ({ color }) => (
  <svg width="47" height="62" viewBox="0 0 47 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M47 13.5625V56.1875C47 59.3977 44.3697 62 41.125 62H5.875C2.63029 62 0 59.3977 0 56.1875V13.5625C0 10.3523 2.63029 7.75 5.875 7.75H15.6667C15.6667 3.4766 19.1807 0 23.5 0C27.8193 0 31.3333 3.4766 31.3333 7.75H41.125C44.3697 7.75 47 10.3523 47 13.5625ZM23.5 4.84375C21.8776 4.84375 20.5625 6.1449 20.5625 7.75C20.5625 9.3551 21.8776 10.6562 23.5 10.6562C25.1224 10.6562 26.4375 9.3551 26.4375 7.75C26.4375 6.1449 25.1224 4.84375 23.5 4.84375ZM35.25 18.6484V16.2266C35.25 16.0339 35.1726 15.8491 35.0349 15.7128C34.8972 15.5765 34.7104 15.5 34.5156 15.5H12.4844C12.2896 15.5 12.1028 15.5765 11.9651 15.7128C11.8274 15.8491 11.75 16.0339 11.75 16.2266V18.6484C11.75 18.8411 11.8274 19.0259 11.9651 19.1622C12.1028 19.2985 12.2896 19.375 12.4844 19.375H34.5156C34.7104 19.375 34.8972 19.2985 35.0349 19.1622C35.1726 19.0259 35.25 18.8411 35.25 18.6484Z"
      fill={color ? color : '#0C84FC'}
    />
  </svg>
);
