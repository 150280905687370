import { useTheme } from '@mui/material';
import React from 'react';
import { Flex, WhiteContainer } from '../../../../common';
import { SmallCircle } from '../../../../components/RouteInfo/RouteInfo.style';
import { CustomText } from '../../../../styles';
import CountUp from 'react-countup';

interface ProductCountCardProps {
  image: any;
  topText: string;
  bottomText: string;
  totalCount: number;
}

export const ProductCountCard: React.FC<ProductCountCardProps> = ({
  image,
  topText,
  bottomText,
  totalCount,
}) => {
  const theme = useTheme();
  return (
    <WhiteContainer>
      <Flex>
        <SmallCircle size={42}>{image}</SmallCircle>
        <Flex column gap="0px" noCenter>
          <CustomText fontSize={0.9} bold={700} uppercase>
            {topText}
          </CustomText>
          <CustomText fontSize={0.9} bold={700} uppercase>
            {bottomText}
          </CustomText>
        </Flex>
        <CustomText fontSize={2} color={theme.background.blue}>
          {totalCount ? <CountUp end={totalCount} duration={2} /> : '0'}
        </CustomText>
      </Flex>
    </WhiteContainer>
  );
};
